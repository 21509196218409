import React from "react";
import MediaNetAd from '../../components/mediaNetAd';
import JoinPg from '../join/index';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './index.scss';

export default function JoinPages() {
  const { id } = useParams();
  const gamePaid = useSelector(({ firestore: { data } }) => data.games && data.games[id] && data.games[id].type !== 'paid');

  return (
    <div className="lobby page">
      <div className="content">
        {gamePaid && <MediaNetAd />}
        <JoinPg />
      </div>
    </div>
  );
}