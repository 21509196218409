
import React from "react";
import './index.scss';
import AceOfSpades from '../cards-big/aceOfSpades';
import ThreeOfSpades from '../cards-big/threeOfSpades';
import FourOfSpades from '../cards-big/fourOfSpades';
import SevenOfSpades from '../cards-big/sevenOfSpades';
import TenOfSpades from '../cards-big/tenOfSpades';
import JackOfSpades from '../cards-big/jackOfSpades';
import QueenOfSpades from '../cards-big/queenOfSpades';
import KingOfSpades from '../cards-big/kingOfSpades';

import AceOfDiamonds from '../cards-big/aceOfDiamonds';
import TwoOfDiamonds from  '../cards-big/twoOfDiamonds';
import ThreeOfDiamonds from  '../cards-big/threeOfDiamonds';
import FourOfDiamonds from  '../cards-big/fourOfDiamonds';
import FiveOfDiamonds from  '../cards-big/fiveOfDiamonds';
import SevenOfDiamonds from  '../cards-big/sevenOfDiamonds';

import SevenOfHearts from '../cards-big/sevenOfHearts';
import ThreeOfClubs from '../cards-big/threeOfClubs';
import SevenOfClubs from '../cards-big/sevenOfClubs';
import SixOfClubs from '../cards-big/sixOfClubs';



export default function PokerRankingsList() {

  return (
    <ol className="poker-hand-list">
      <li>
        <p>1. Royal Flush</p>
        <div>
          <AceOfSpades />
          <KingOfSpades />
          <QueenOfSpades />
          <JackOfSpades />
          <TenOfSpades />
        </div>
      </li>
      <li>
        <p>2. Straight Flush</p>
        <div>
          <AceOfDiamonds />
          <TwoOfDiamonds />
          <ThreeOfDiamonds />
          <FourOfDiamonds />
          <FiveOfDiamonds />
        </div>
      </li>
      <li>
        <p>3. Four of a Kind</p>
        <div>
          <SevenOfClubs />
          <SevenOfDiamonds />
          <SevenOfHearts />
          <SevenOfSpades />
        </div>
      </li>
      <li>
        <p>4. Full House</p>
        <div>
          <ThreeOfClubs />
          <ThreeOfDiamonds />
          <SevenOfClubs />
          <SevenOfDiamonds />
          <SevenOfHearts />
        </div>
      </li>
      <li>
        <p>5. Flush</p>
        <div>
          <AceOfSpades />
          <ThreeOfSpades />
          <FourOfSpades />
          <SevenOfSpades />
          <TenOfSpades />
        </div>
      </li>
      <li>
        <p>6. Straight</p>
        <div>
          <ThreeOfClubs />
          <FourOfSpades />
          <FiveOfDiamonds />
          <SixOfClubs />
          <SevenOfHearts />
        </div>
      </li>
      <li>
        <p>7. Three of Kind</p>
        <div>
          <SevenOfDiamonds />
          <SevenOfHearts />
          <SevenOfSpades />
        </div>
      </li>
      <li>
        <p>8. Two Pair</p>
        <div>
          <SevenOfDiamonds />
          <SevenOfHearts />
          <ThreeOfClubs />
          <ThreeOfDiamonds />
        </div>
      </li>
      <li>
        <p>9. Pair</p>
        <div>
          <ThreeOfClubs />
          <ThreeOfDiamonds />
        </div>
      </li>
      <li>
        <p>10. High Card</p>
        <div>
          <AceOfSpades />
        </div>
      </li>
    </ol>
  )
}