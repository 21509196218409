import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';

import {
  Warning,
  Cancel
} from '@material-ui/icons';

import { Modal } from '@material-ui/core';
import './index.scss';

export default function ShareModal({
  open,
  close,
  title,
  message,
}) {

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      disableBackdropClick
      open={open}
      onClose={close}
      className="round-txt-modal"
    >
      <div className="warning-box">
        <div className={'warning-header'}>
          <h3>
            <Warning /> {title}
          </h3>
          <Button onClick={close}> <Cancel /></Button>
        </div>
        <div className="message">
          {message}
        </div>
      </div>
    </Modal>

  )
}