import React, { useState, useEffect, useRef } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';


import {
  Grid,
  Typography,
  Button
} from '@material-ui/core';

import ArticleAd from  '../../components/articleAd';
import './ad.scss';

export default function () {
  const history = useHistory();
  const [count, setCount] = useState(5);
  const gameId = useSelector(state => state.free ? state.free.gameId : null);
  const delay = 1000;
  
  useInterval(() => {
    // Your custom logic here
    if(count !== 0) setCount(count - 1);
    if(count === 0) history.push(`/lobby/${gameId}`);
  }, delay);

  // TODO: GIve the option to pay to remove ads
  let showAction = false ? 
  <React.Fragment>
    <Grid item xs={6}>
      <Button variant="contained" color="primary"  onClick={payClick}>
        Remove Ads Now!
      </Button>
    </Grid>
    <Grid item xs={6}>
      <Button variant="contained" color="primary"  onClick={freeClick}>
        Continue Free Mode
      </Button>
    </Grid>
  </React.Fragment>
  :
  <Typography variant="h3" gutterBottom>
    <span className="game-loading-txt">Game Loading in: {count}</span>
  </Typography>;

  function payClick() {
    history.push("/payment");
  }

  function freeClick() {
    // Navigate to return to game after 5 seconds of ads
  }
  return (
    <div className="ad-page">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {/* <Grid item xs={6}>
          <ArticleAd />
        </Grid>
        <Grid item xs={6}>
          <ArticleAd />
        </Grid> */}
        <Grid item xs={12}>
          
        </Grid>
        <Grid item xs={12}>
          {showAction}
          <LinearProgress />
        </Grid>
      </Grid>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
  )
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    window.document.title = `Texas Hold Em With Friends - Creating game...`;

  }, [delay]);
}