import React, { useEffect } from "react";
import './index.scss';
import {
  useParams,
  useHistory
} from "react-router-dom";
import {
  Grid,
  Box
} from '@material-ui/core';
import HeaderBar from '../../components/appbar';
import Banner from '../../components/bannerAd';

export default function NoGamePage() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - No game found`;
  });

  let { id } = useParams();
  const history = useHistory();
  const tryAgain = (e) => {
    e.preventDefault();
    history.push(`/game/${id}`);
  }

  return (
    <main className="nogame-page page">
      <HeaderBar />
      <div className="content">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <h1>Sorry, no game found.</h1>
            <p>Think the game is still going on? <a href="#" onClick={tryAgain}>Try joining the game again</a></p>
            <p>Either the game ended a while ago, your time is up, or there was an error in our database. Please contact us at <a href="mailto:&#116;&#101;&#120;&#097;&#115;&#119;&#105;&#116;&#104;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#112;&#111;&#107;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">texaswithfriendspoker@gmail.com</a> with the following ID:</p>
            <h3 className="game-id">{ id }</h3>
          </Grid>
        </Grid>

        {/* <Box mt={3}>
          <Banner />
        </Box> */}
      </div>
    </main>
  );
}