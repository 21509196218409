import React, {} from 'react';
import { Button } from '@material-ui/core';
import PatreonLogo from './patreonLogo';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  patreonButton: {
    backgroundColor: 'rgb(232, 91, 70);',
    '&:hover': {
      backgroundColor: 'rgb(232, 91, 70);',
    },
    color: '#FFF',
    padding: '0 16px'
  },
  displayText: {
   padding: '8px;'
  },
};
function DonateButton({ buttonText, classes, variation }) {
  const displayText = buttonText || 'Our Patreon'

  return (
    <React.Fragment>
      {variation === 'big' && <Button 
        className={classes.patreonButton}
        href="https://www.patreon.com/texasholdemwithfriends"
        target="_blank">
        <PatreonLogo />
      </Button>}
      {variation === 'small' && <Button
          className={classes.patreonButton}
          href="https://www.patreon.com/texasholdemwithfriends"
          target="_blank">
          <div className={classes.displayText}>{displayText}</div>
        </Button>
      }
    </React.Fragment>
  );
}

export default withStyles(styles)(DonateButton)