import 'firebase/firestore' // <- needed if using firestore\
import 'firebase/auth'; // <-- needed for authentication
import 'firebase/analytics'; // <!-- needed for analytics
import 'firebase/functions';
import firebase from 'firebase/app';
import { createStore, combineReducers, compose } from 'redux';
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import { free } from './reducers';

const firebaseConfig = {
  apiKey: "AIzaSyBTr8AHojZ5eQCEGr3z-nHIFSTtsItsOys",
  authDomain: "localhost",
  databaseURL: "https://texas-hold-em-w-friends.firebaseio.com",
  projectId: "texas-hold-em-w-friends",
  storageBucket: "texas-hold-em-w-friends.appspot.com",
  messagingSenderId: "789916523086",
  appId: "1:789916523086:web:6d07479502e0f3a78f811f",
  measurementId: "G-QSF74HR9Z3"
}

// react-redux-firebase config
const rrfConfig = {
  games: 'games',
  userProfile: "users",
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

firebase.analytics();

// Initialize other services on firebase instance
const db = firebase.firestore();
if (window.location.hostname === "localhost") {
  db.settings({
    host: "localhost:8080",
    ssl: false
  });
}

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore
    free,
})

const initialState = {};
export const store = createStore(rootReducer, initialState);
export const auth = firebase.auth();
export const firebasedb = db;
export const firebasefunctions = firebase.functions();
export const fireProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    createFirestoreInstance // <- needed if using firestore
}
