import React, { useEffect }  from "react";
import  './contact.css';
import {
  Grid,
  Button
} from '@material-ui/core';
import HeaderBar from '../../components/appbar';
import AceOfClubs from '../../components/cards/aceOfClubs';
import AceOfSpades from '../../components/cards/aceOfSpades';
import AceOfDiamonds from '../../components/cards/aceOfDiamonds';
import AceOfHearts from '../../components/cards/aceOfHearts';

export default function Contact() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Contact Us`;
  });

  return (
    <main className="page">
      <HeaderBar />

      <div className="content contact-content">
        <h1>We'd love to hear from you!</h1>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <p>
              <h3>Have a question? Issue? or just wanted to say hi?</h3>
              <Button variant="contained" color="primary" href="mailto:&#116;&#101;&#120;&#097;&#115;&#119;&#105;&#116;&#104;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#112;&#111;&#107;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">
                Contact Us
              </Button>
              <div className={"aces"}>
                <AceOfClubs />
                <AceOfSpades />
                <AceOfDiamonds />
                <AceOfHearts />
              </div>
            </p>
          </Grid>
        </Grid>
      </div>
    </main>
  );
}