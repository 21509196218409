import React, { useEffect, useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { createFreeGame } from '../../store/actions';
import { fetch$ } from '../../utils';
import {
  SentimentVerySatisfied
} from '@material-ui/icons';
import {auth} from '../../store';
import './index.scss';

export default function TableQueue () {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [ name, setName ] = useState('');
  const [ redeemedFreeGame, setRedeemedFreeGame ] = useState(0);
  const updateName = (e) => {
    setName(e.target.value);
  }
  const [joinClicked, setJoinClicked] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'show',
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  }));
  useFirestoreConnect([
    {
      collection: `tablequeue`
    },
    {
      collection: `games`
    }
  ]);
  const classes = useStyles();
  const tablequeue = useSelector(
    ({ firestore: { data } }) => {
      return data.tablequeue;
    }
  );
  const games = useSelector(({ firestore: { data } }) => data.games  )
  const uid  = useSelector((state) => state.firebase.auth.uid);

  const startRedeemTimer = async () => {
    let resp = await fetch$('redeemfreegame', 'POST', {
      body: JSON.stringify({
        uid
      })
    });
  };

  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Table Queue`;

    if (games && tablequeue && redeemedFreeGame <= 0 && isFirstInQueue()) {
      // console.log(' in IF and setting timer redeem')
      startRedeemTimer();
      setRedeemedFreeGame(redeemedFreeGame + 1);
    }

    return () => { document.body.className = ''; }
  }, [games, tablequeue]);

  const QueueListOl = () => {
    return (<ol>
      {tablequeue.queue.queueList.map((player, key) => {
        if (player.id === window.btoa(uid)) {
          return <li className="your-position" key={key}>You {`<------`}</li>
        } else {
          return <li key={key}><SentimentVerySatisfied /></li>
        }
      })}
    </ol>);
  };

  const makeGame = async (e) => {
    e.preventDefault();

    let resp = await fetch$('freeGame', 'POST', {
      body: JSON.stringify({
        uid,
        fromQueue: true
      })
    });

    if (resp.gameId) {
      dispatch(createFreeGame(resp));
      history.push('/game-with-ads');
    }
  };

  const StartGame = () => {
    return (<div>
      <a className="start-game-link" onClick={makeGame}>Time to start your game</a>
      </div>);
  };

  const isFirstInQueue = () => {
    // console.log('----games', games);
    // console.log('---tablequeue', tablequeue);
    // console.log('----uid,', uid)
    let gamesInPlay = Object.fromEntries(Object.entries(games).filter(([,value])=> value !== null));
    let checkIfFirst = tablequeue.queue.queueList.filter((player, index) => index === 0 && player.id === window.btoa(uid) && Object.keys(gamesInPlay).length
    < 1001) // THIS NEEDS TO MATCH AMOUNT OF freeGameArr TABLES IN THE savefreegame CLOUD FUNCTION

    // console.log('checkIfFirst----', checkIfFirst);
    return checkIfFirst.length >= 1;
  };

  const LoadedElem = () => {
    let playerInGame = tablequeue.queue.queueList.filter(player => player.id === window.btoa(uid));

    return (<div>
      <h1>Queue</h1>
      <p>Check this page and see where you are in the queue. Once it's your turn, you'll have 7 minutes to redeem your table.</p>
      {
        playerInGame.length >= 1 && isFirstInQueue() ? <StartGame /> : <QueueListOl />
      }
    </div>);
  };

  return (
    <div className="lobby table-queue">
      {
        isLoaded(tablequeue) && isLoaded(games) ? isEmpty(tablequeue) || isEmpty(games) ? <div></div> : <LoadedElem /> : null}

      
    </div>
  );
}