
const getYourBet = ({
  tableData,
  betAmt,
  turnStatus,
}) => {
  let betAmount = 0;

  if (turnStatus === 'complete') {
    betAmount = betAmt;
  } else {
    betAmount = tableData.subtotal_bet;
  }

  // console.log('current bet amount', betAmt);

  return betAmount; 
};

export default getYourBet;