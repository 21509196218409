import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  TextField,
  CircularProgress,
  Box
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader';
import ShareModal from '../../components/shareModal';
import MediaNetAd from '../../components/mediaNetAd';
import postscribe from 'postscribe';
import Countdown from 'react-countdown';
import {
  startGameAuthenticatedUser
} from '../game/lib';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {auth} from '../../store';
import Banner from '../../components/bannerAd';
import {
  SentimentVerySatisfied
} from '@material-ui/icons';
import './lobby.scss';
import statefarmad from  '../../images/state-farm-ad-2.png';

export default function Lobby () {
  const [chips, setChips] = React.useState(80);
  const [mediaNetSet, setMediaNetSet] = React.useState(0);
  const [bigBlind, setBigBlind] = React.useState(4);
  const { id } = useParams();
  const history = useHistory();
  const [name, setName] = useState('Host');
  const [startClicked, setGameStartClicked] = React.useState(false);
  const gamePaid = useSelector(({ firestore: { data } }) => data.games && data.games[id] && data.games[id].type !== 'paid');

  useFirestoreConnect([
    {
      collection: `games`,
      doc: id
    }
  ]);

  const handleChipChange = event => {
    let chipsSelected = event.target.value;
    setChips(chipsSelected);
  };
  const handleBigBlindChange = event => {
    let bigBlindSelected = event.target.value;
    console.log('bigBlindSelected---', bigBlindSelected);
    setBigBlind(bigBlindSelected);
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'show',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: '80%',
      height: '100%',
      paddingBottom: '16px'
    },
    icon: {
      width: '100%',
      height: '100%',
      opacity: 0.3
    },
    subHeader: {
      fontSize: '24px',
    },
    loaders: {
      width: '100%',
    },
    tile: {
      borderLeft: '1px solid #eee',
      borderRight: '1px solid #eee',
      borderTop: '1px solid #eee',
    },
    button: {
      paddingBottom: '16px',
      paddingTop: '16px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  }));

  const classes = useStyles();
  const game = useSelector(
    ({ firestore: { data } }) => {
      return data.games && data.games[id];
    }
  );
  const uid  = useSelector((state) => state.firebase.auth.uid);

  const updateName = (e) => {
    if (e.target.value) {
      setName(e.target.value);
    } else {
      setName('Host');
    }
  }
  const checkIfOwner = (game) => {
    if (game && game.organizerId) {
      if (window.atob(game.organizerId) === uid) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Lobby`;

    if (mediaNetSet <= 0 && isLoaded(game) && !isEmpty(game)) {
      setMediaNetSet(mediaNetSet + 1);
    }


    // listen to see if we are in any part of the game
    if (game) {
      if (game.gamePosition === 'pre-flop' ||
      game.gamePosition === 'flop' ||
      game.gamePosition === 'turn' ||
      game.gamePosition === 'river') {
  
        // if the game has already started, check if your AUTH id matches players in the game, if so, forward to game
        let isInGame = game.players.filter(plyr => plyr.opponentId === window.btoa(uid));
  
        if (isInGame.length >= 1) {
          history.push(`/game/${id}`);
        }
      }
    }

    return () => { document.body.className = ''; }
  }, [game]);

  const play = () => {
    setGameStartClicked(!startClicked);
    // ONLY AUTHENTICATED USER WHO CREATED GAME SHOULD HAVE THIS OPTION TO HIT PLAY

    auth.onAuthStateChanged((user) => {
      if (user) {
        const { isAnonymous, uid } = user; 
        const gameId = id;
        startGameAuthenticatedUser({
          uid: window.btoa(uid),
          gameId,
          chips,
          name,
          bigBlind
        }).then((cards) => {
          history.push(`/game/${id}`);
        });   
      } else {

      }

    }); 
  }
 
  const isDisabled = () => {
    if (game.players.length <= 1) return true;
    
    return startClicked;
  }

  const gameStarted = () => {
    return game.gamePosition === 'pre-flop' ||
    game.gamePosition === 'flop' ||
    game.gamePosition === 'turn' ||
    game.gamePosition === 'river';
  };
  const getChipSelect = () => {
    let chipStartAt = 80;
    let chipEndAt = 400;
    const incrementOf = 20;
    let chipArray = [];

    while (chipStartAt < chipEndAt + incrementOf) {
      chipArray.push(chipStartAt);
      chipStartAt = chipStartAt + incrementOf;
    }
    return chipArray;
  };

  const getBlindSelect = () => {
    let blindStartAt = 4;
    let blindEndAt = 40;
    const incrementOf = 4;
    let blindArray = [];

    while (blindStartAt < blindEndAt + incrementOf) {
      blindArray.push(blindStartAt);
      blindStartAt = blindStartAt + incrementOf;
    }
    return blindArray;
  };

  const getFriendsInLobbyTxt = () => {
    let txt = '';
    if (checkIfOwner(game)) {
      if (game.players.length >= 2) {
        txt = `3. Wait for your friends to join (${game.players.length})`;
      } else {
        txt = `3. Wait for your friends to join - One friend required to start the game. `;
      }
    } else {
      txt = `Friends in lobby (${game.players.length})`;
    }
    return txt;
  };

  return (
    <div>
        {
          !isLoaded(game) ? <div className="loading-game-table"><Loader /> </div>: null
        }

        {
          isLoaded(game) ? isEmpty(game) ? <Redirect to={`/nogame/${id}`} /> : <div>


          <h1>Lobby</h1>
            {gameStarted() ? <div>
              <h1>Looks like you're late to the party.</h1>
              <p>This game has already started</p>
            </div> : <div className="lobby-details">

            <div className="game-setup">
              <div className="game-details">
              {checkIfOwner(game) ? <ShareModal gameId={id} /> : null }

              {checkIfOwner(game) ?
                  <React.Fragment>
                  <h3>2. Set up the game</h3>
                  <h4>Enter your name</h4>
                  <div className="form-elem">
                    <FormControl className={`${classes.formControl}`}>
                      <TextField
                        id="standard-helperText"
                        label="Name"
                        variant="outlined"
                        onChange={updateName}
                      />
                    </FormControl>
                  </div> 
                </React.Fragment> : <p className="waiting-for-host">Waiting for host to start game. Not on the list? <a href={`/join/${id}`}>Click here to try and join the game.</a></p>
              }
            
            {checkIfOwner(game) ? 
            <React.Fragment>
              <h4>Starting chips</h4>
              <div className="form-elem">
              <FormControl className={classes.formControl}>
              <TextField
                select
                value={chips}
                onChange={handleChipChange}
                variant="outlined"
              >
                {getChipSelect().map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
              </TextField>
              </FormControl>
              </div>

              <h4>Set Blinds</h4>
              <div className="form-elem">
                <FormControl className={classes.formControl}>
                <TextField
                  select
                  value={bigBlind}
                  onChange={handleBigBlindChange}
                  variant="outlined"
                >
                  {getBlindSelect().map((value, index) => <MenuItem key={index} value={value}>{`${value} / ${value / 2}`}</MenuItem>)}
                </TextField>
                </FormControl>
              </div>

            </React.Fragment> : null}
            </div>
          </div>

          <div className="lobby-players">
            <h3>{getFriendsInLobbyTxt()}</h3>

            <ul>
              {game.players.map((player, key) => {
                // console.log(player, '------')
                if (player.name === 'Host') {
                  return <li key={key}><SentimentVerySatisfied /> { checkIfOwner(game) ? name === 'Host' ? 'Game Creator (You)' : `${name} (You)` : 'Game creator'}</li>
                }
                else {
                  return <li key={key}><SentimentVerySatisfied /> {player.name}</li>
                }
                })}
            </ul>

            { checkIfOwner(game) ? <div className="lobby-start-game">
              <Button 
                variant="contained" 
                color="primary" 
                disabled={isDisabled()} 
                onClick={play}>{startClicked ? 'Starting Game...' : 'Start the game!'}</Button>
            </div> : null}
          </div>

          </div>}

          {gamePaid && <div className="ad-box state-farm">
            <a href="https://yournumberoneagent.com" target="_blank" rel="noopener noreferrer"><img src={statefarmad} /></a>
          </div>}

        </div>
          : null
        }
    </div>
  );
}