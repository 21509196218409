import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

export default function() {
  const history = useHistory();
  const click = () => { 
    // Made click handler for possibility of more complexities
    
    history.push("/payment");
  };  

  return (
    <div className="tier-pay-info">
      <h1>No Ads For $1/Hr</h1>
      <ul>
        <li>Remove all ads for everyone in game for $1/hr</li>
        <li>No ads in the lobby</li>
        <li>No ads during each round of betting</li>
        <li>No ads when your friends join your table</li>
        <li>Ad-free time only for one game</li>
      </ul>
      <Button variant="contained" color="primary" onClick={click}>Play with no ads</Button>
    </div>
  );
}