import postYourBet from './postYourBet';

const bet = ({
  type,
  tableData,
  currentPlayerTurn,
  game,
  gameId,
  raiseVal,
}, setBetAmt, setTurnStatus, setRaiseVal, setDisplayRaiseOpts) => {
  const {
    bankroll,
    subtotal_bet, 
    spotAtTable,
  } = tableData;

  switch(type) {
    case 'allin':
      // console.log('trying to go alll in----->');
      setBetAmt(bankroll);
      setTurnStatus('complete');

      postYourBet({
        betAmt: bankroll,
        currentPlayerTurn,
        typeOfPlay: 'raise',
        gameId,
      }).then(() => {
        setTurnStatus('unknown');
        setRaiseVal('default');
        setDisplayRaiseOpts(false);
      });
      break;
    case 'call':
      const { currentBetAmt } = game;
      let betAmt;
      // let's check if calling means going all in. If the current bet is greater then your bank roll, 
      if (currentBetAmt >= bankroll + subtotal_bet) {
        betAmt = bankroll + subtotal_bet;
      } else {
        betAmt = currentBetAmt - subtotal_bet;
      }
      setBetAmt(betAmt);
      setTurnStatus('complete');

      postYourBet({
        betAmt,
        currentPlayerTurn: spotAtTable,
        typeOfPlay: 'call',
        gameId
      }).then(() => {
        setTurnStatus('unknown');
        setRaiseVal('default');
        setDisplayRaiseOpts(false);
      });
      break;
    case 'raise':
      setBetAmt(raiseVal);
      setTurnStatus('complete');
      postYourBet({
        betAmt: raiseVal - subtotal_bet, // API will handle matching the CALL, plus adding adding the bet amount on top of the call.
        currentPlayerTurn: game.currentPlayerTurn,
        typeOfPlay: 'raise',
        gameId
      }).then(() => {
        setTurnStatus('unknown');
        setRaiseVal('default');
        setDisplayRaiseOpts(false);
      });
      break;
    case 'fold':
      setTurnStatus('complete');
      postYourBet({
        currentPlayerTurn: game.currentPlayerTurn,
        typeOfPlay: 'fold',
        gameId
      }).then(() => {
        setTurnStatus('unknown');
        setRaiseVal('default');
        setDisplayRaiseOpts(false);
      });
      break;
    default:
      break;
  }
 
  //   let betOrCallVal = raiseVal; // setting what person confirmed to bet.
  //   let typeOfPlay = 'raise';

  //   setBetAmt(betOrCallVal);

  //   // HIDE RAISE ACTIONS MANUALLY TO PREVENT DOUBLE TAP OF YOUR TURN ACTIONS WHILE POSTING TO DB:
  //   setTurnStatus('complete');

  //   postYourBet({
  //     betAmt: betOrCallVal,
  //     currentPlayerTurn: game.currentPlayerTurn,
  //     typeOfPlay
  //   }).then(() => {
  //     setTurnStatus('unknown');
  //   });;
  
};

export default bet;
