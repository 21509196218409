import React, {useState, useEffect} from 'react';
import Banner from '../../components/bannerAd';
import {
  Box,
  TextField,
  FormControl,
  Button
} from '@material-ui/core';
import Loader from '../../components/loader';
import { useFirestoreConnect, isLoaded, isEmpty  } from 'react-redux-firebase';
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { fetch$ } from '../../utils';
import {auth} from '../../store';

// @TODO add player 
export default function Join () {
  const history = useHistory();
  const { id } = useParams();
  const [ name, setName ] = useState('');
  const [joinClicked, setJoinClicked] = React.useState(false);
  const updateName = (e) => {
    setName(e.target.value);
  }
  useFirestoreConnect([
    {
      collection: `games`,
      doc: id
    }
  ]);
  const uid  = useSelector((state) => state.firebase.auth.uid);
  const game = useSelector(
    ({ firestore: { data } }) => {
      let game = data.games && data.games[id];

      if (game && game.players) {
        let match = game.players.filter(plyr => window.atob(plyr.opponentId) === uid);
        if (match.length >= 1) {
          if (game.gamePosition === 'pre-flop' ||
          game.gamePosition === 'flop' ||
          game.gamePosition === 'turn' ||
          game.gamePosition === 'river') {
            history.push(`/game/${id}`);
          } else {
            history.push(`/lobby/${id}`);
          }
        }
      }

      return game;
    }
  );
  const play = async (e) => {
    setJoinClicked(!joinClicked);

    e.preventDefault();
    // NOTE: authenticate the user
    auth.signInAnonymously().catch((error) => {
      // Handle Errors here.
      let errorCode = error.code;
      let errorMessage = error.message;
    });
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        // listens that the anonymouse authentication has occured
        const isAnonymous = user.isAnonymous;
        const uid = user.uid;
        
        await fetch$('addPlayer', 'POST', {
          body: JSON.stringify({
            gameId: id,
            name,
            id: window.btoa(uid)
          }),
        });
        history.push(`/lobby/${id}`);
        
          // history.push(`/game/${window.btoa(data.orderID)}`);
      } else {
        // User is signed out.
        // ...
      }
    });
  }

  const gameStarted = () => {
    return game.gamePosition === 'pre-flop' ||
    game.gamePosition === 'flop' ||
    game.gamePosition === 'turn' ||
    game.gamePosition === 'river';
  };


  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Join the game`;
  });

  return (
    <div>
      {
        !isLoaded(game) ? <div className="loading-game-table"><Loader /> </div>: null
      }

      {
        isLoaded(game) ? isEmpty(game) ? <Redirect to={`/nogame/${id}`} /> : <div>

        { gameStarted() ? <div>
                <h1>Looks like you're late to the party.</h1>
                <p>This game has already started</p>

                {/* <Box mt={3} className="ad-box">
                  <Banner />
                </Box> */}
              </div>  : <div>
                <h1>You've been invited to Play</h1>
                {/* <Box mt={3} className="ad-box">
                  <Banner />
                </Box> */}

                <div className="guest-checkin">
                  <p>Enter your name, and join the fun</p>
                  <div className="guest-name">
                    <FormControl>
                      <TextField
                        id="standard-helperText"
                        label="Enter your name"
                        onChange={updateName}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>

                  <div>
                    <Button variant="contained" color="primary" disabled={joinClicked} onClick={play}>{joinClicked ? 'Joining Game...' : 'Join Table!'}</Button>
                  </div>
                </div>
              </div> }
      </div>
        : null
      }
    </div>
  );
}