const getYourTableData = (players, uid) => {
  if(!players) return;
  let yourData = players.filter(player => {
    let yourId = uid ? window.btoa(uid) : 'CREATOR_ID';

    // console.log('window.btoa', yourId);
    return player.opponentId === yourId; //this will be your AUTH ID from db
  });
  // console.log('your data', yourData[0]);
  return yourData[0];
};

export default getYourTableData;