import React, { useEffect } from 'react';
import Free from  './free';
import Pay from './pay';
import {
  Grid,
  Box
} from '@material-ui/core';
import Banner from '../../components/bannerAd';
import MediaNetAd from '../../components/mediaNetAd';
import HeaderBar from '../../components/appbar';
import './tier.scss';

export default function() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Choose your game`;
    // Replace with React helmet
    // https://github.com/nfl/react-helmet
  });

  return (
    <main className="page tier">
      <HeaderBar />
      <div className="content">

        <MediaNetAd />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="top"
        >
          <Grid item xs={12} sm={6}>
            <Free />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Pay />
          </Grid>
        </Grid>
        {/* <Box mt={3}>
          <Banner />
        </Box> */}
      </div>
    </main>
  );
}