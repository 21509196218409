import React from "react";
import './index.scss';
import {
  Whatshot,
  DataUsage,
  Block
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Chipcount from '../../components/chipcount/chipcount';
import YourHand from '../../components/YourHand';


export default function YourGame(props) {
  const {
    yourCards,
    yourData,
    currentPlayerTurn,
    dealerId,
    smallBlindId,
    bigBlindId,
    yourBet,
    yourTurnStatus,
    manualGamePosition,
    retryOwnCards
  } = props;

  const {
    bankroll,
    spotAtTable,
    subtotal_bet,
    status
  } = yourData;

  const inBetweenManualRound = (gamePosition) => {
    // console.log('......gamePosition', gamePosition);
    return gamePosition === 'flop' || gamePosition === 'turn' || gamePosition === 'pre-flop'
  };

  const getPlayerClass = (data) => {
    let {
      status,
      spotAtTable
    } = data.yourData;
    let {currentPlayerTurn} = data;

    let elemClass = `your-spot`;

    if (status === 'FOLD') {
      elemClass = `${elemClass} player-fold`;
    } else if (status === 'BUST') {
      if (manualGamePosition) {
        if (manualGamePosition === 'river') {
          elemClass = `${elemClass} player-bust`;
        }
      } else {
        elemClass = `${elemClass} player-bust`; 
      }
    }

    if (spotAtTable === currentPlayerTurn && status !== 'FOLD' && status !== 'BUST' && !manualGamePosition) {
      elemClass = `${elemClass} current-turn`;
    }

    return elemClass;
  };

  const getBetAmt = (yourBet, defaultBet) => {
    return (<div className="your-bet"><span className="bet-amt">Your bet <DataUsage className="chip-icon"></DataUsage> {yourBet || defaultBet}</span></div>);
  };

  const getChipCount = (bankroll, yourBet, manualGamePosition) => {
    // console.log('>>>>>>>>>>>>>>--------- manualGamePosition', manualGamePosition);
    // console.log('<<<<<<<<<<<< manualGamePosition', manualGamePosition)
    if (manualGamePosition && manualGamePosition !== 'river') {
      return (<CircularProgress size={20} />);
    } else {
      return yourTurnStatus === 'complete' ? bankroll - yourBet : bankroll
    }
  };

  return (
    <div className={getPlayerClass({
      yourData,
      currentPlayerTurn,
      })}>
      <div>
        { yourBet || subtotal_bet ? getBetAmt(yourBet, subtotal_bet) : null } {/* false will be if bet is confirmed */}

        <div className="your-info">
          { dealerId === spotAtTable? <Whatshot className="dealer"></Whatshot> : null}
          { smallBlindId === spotAtTable ? <span className="small-blind">SB</span> : null }
          { bigBlindId === spotAtTable ? <span className="big-blind">BB</span> : null }
          { bankroll  || status !== 'BUST' || inBetweenManualRound(manualGamePosition) ? 
          <Chipcount chips={getChipCount(bankroll, yourBet, manualGamePosition)}></Chipcount>
          :
          <div className="bust-txt"><Block className="bust-icon"></Block>Bust</div>
          }
          
        </div>
        
        <div className="your-cards">
          <YourHand
            retryOwnCards={retryOwnCards}
            yourCards={yourCards}
          /> 
        </div>
      </div>
    </div>
  );
}