export default {
  organizerID: 'abc123',
  smallBlindPlayer: null,
  bigBlindPlayer: null,
  currentChipBet: 2,
  minBet: null,
  minRaise: null,
  currentBet: null,
  currentPlayerTurn: '445',
  dealerId: 'CCC',
  bigBlindId: '222',
  smallBlindId: '333',
  gamePosition: 'pre-flop',
  playersAtTable: [
    {
      name: 'Alex',
      id: '111',
      spotAtTable: 1,
      status: 'folded',
      bankRoll: 10
    },
    {
      name: 'Alex2',
      id: '222',
      spotAtTable: 2,
      bankRoll: 20,
    },
    {
      name: 'Alex3',
      id: '333',
      spotAtTable: 3,
      bankRoll: 30
    },
    {
      name: 'Ale4',
      id: '445',
      spotAtTable: 4,
      bankRoll: 16,
    }
    // {
    //   playerName: 'Alex5',
    //   id: '555',
    //   tableSpot: 5,
    //   status: 'fold',
    //   chipAmt: 70
    // },
    // {
    //   playerName: 'Alex6',
    //   id: '667',
    //   tableSpot: 6,
    //   chipAmt: 66
    // },
    // {
    //   playerName: 'Alex7',
    //   id: '777',
    //   tableSpot: 7,
    //   chipAmt: 12
    // },
    // {
    //   playerName: 'Alex8',
    //   id: '888',
    //   tableSpot: 8,
    //   chipAmt: 44
    // }
  ]
};