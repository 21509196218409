import fetch$ from '../../../utils/fetch';

const startGameAuthenticatedUser = async ({uid, gameId, chips, name, bigBlind}) => {
  // NOTE: After authentication, save the transaction
  const resp = await fetch$('start', 'POST', {
    body: JSON.stringify({
      uid: uid || 1,
      gameId,
      chips,
      name,
      bigBlind
    })
  });
  // console.log(resp);
};

export default startGameAuthenticatedUser;