import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { fetch$ } from '../../utils';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { createFreeGame } from '../../store/actions';
import {auth} from '../../store';


export default function({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [playClicked, setFreePlayClicked] = React.useState(false);

  const click = async (e) => { 
    let uid;
    setFreePlayClicked(!playClicked);

    // NOTE: authenticate the user
    auth.signInAnonymously().catch((error) => {
      // Handle Errors here.
      let errorCode = error.code;
      let errorMessage = error.message;
    });
    auth.onAuthStateChanged(async (user) => {

      if (user) {
        uid = user.uid;

        let resp = await fetch$('freeGame', 'POST', {
          body: JSON.stringify({
            uid
          })
        });

        if (resp.gameId) {
          // dispatch(createFreeGame(resp));
          // history.push('/game-with-ads');

          // NOTE: temporarily removing wait time until we get more users using platform, less delay to start playing the game.
          history.push(`/lobby/${resp.gameId}`);
        } else {
          history.push('/join-queue');
        }
        
      } else {
        // User is signed out.
        // ...
      }
    });

  };


  return (
    <React.Fragment>
      <h1>Play FREE</h1>
      <ul>
        <li>Play the game with up to 8 friends for free</li>
        <li>Uninstrusive ads during gameplay</li>
        <li>Game expires in 30 days</li>
        <li>No player "rebuys"</li>
      </ul>
      <Button variant="contained" color="primary" disabled={playClicked} onClick={click}>{playClicked ? 'Creating Game...' : 'Start Free Game'}</Button>
    </React.Fragment>
  );
}