import React, { useEffect, useState } from "react";
import WarningModal from '../WarnModal';
import './timer.scss';
import {
  Alarm
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import { fetch$ } from '../../utils';

export default function TimerComponent(props) {
  const history = useHistory();
  const {timerStart, hrs, owner} = props;
  const [timeLeft, setTimeLeft] = useState(null);
  const [timeOver, setTimeOver] = useState(null);
  const [diff, setDiff] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [hideUpcharge, toggleUpCharge] = useState(true);
  const { id } = useParams();
  const tenMinutes = 600000;
  const calculateTimeLeft = (startTime,hrs) => {
    // five minute difference
    const difference = (startTime + (hrs * 60000 * 60 + (5 * 60000))) - Date.now();
    const endGameDifference = (startTime + 5 * 60000 + (hrs * 60000 * 60 + (5 * 60000))) - Date.now();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: ('0' + Math.floor(difference / (1000 * 60 * 60 * 24))).slice(-2),
        hours: ('0' + Math.floor((difference / (1000 * 60 * 60)) % 24)).slice(-2),
        minutes: ('0' + Math.floor((difference / 1000 / 60) % 60)).slice(-2),
        seconds: ('0' + Math.floor((difference / 1000) % 60)).slice(-2)
      };


      if (difference < tenMinutes && (hideUpcharge && owner)) {
        setShowModal(true);
        toggleUpCharge(false);
      } else if (difference > tenMinutes) {
        setShowModal(false);
      }

      setTimeLeft(`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`);
    } else {
      timeLeft = {
        days: ('0' + Math.floor(endGameDifference / (1000 * 60 * 60 * 24))).slice(-2),
        hours: ('0' + Math.floor((endGameDifference / (1000 * 60 * 60)) % 24)).slice(-2),
        minutes: ('0' + Math.floor((endGameDifference / 1000 / 60) % 60)).slice(-2),
        seconds: ('0' + Math.floor((endGameDifference / 1000) % 60)).slice(-2)
      };

      setTimeOver(true);
      setTimeLeft(`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`);
      hideUpcharge && setShowModal(true);
      toggleUpCharge(false);


      if (endGameDifference <= 0) {
        fetch$('gameOver', 'POST', {
          body: JSON.stringify({
           gameId: id,
          })
        }).then(() => {
          history.push(`/tier`);
        });
      }
    }
  };

  // Broke this up to multiple tasks
  // Laying the UI foundation for this interaction
  // @Lead Owner to extend time
  const payToExtend = () => {
    setShowModal(false);
    
  }

  const close = () => {
    setShowModal(false);
  }
  
  useEffect(() => {
    setTimeout(() => {
      calculateTimeLeft(timerStart, hrs);
    }, 1000);

    return () => {};
  });

  return (
    <span className="timer-component">
      <span className={!timeOver ? '' : 'ending'}><Alarm></Alarm>{!timeOver ? 'Ad-free for' : 'Game ending in'} {timeLeft} {diff}</span>

      <WarningModal 
        open={showModal}
        close={close}
        title={`Your Ad-Free Time ${setTimeLeft === '0:00:00' ? 'Has Run Out' : 'Is Running Out' }`}
        message={(
          <div>
            Extend your Ad-Free time for $3/hr or let the game end once your ad-free time is up.
            <ul class="option-list">
              <li> 
                <Link 
                  className={'pay-link'}
                  color="primary"
                  to={`/payment/${id}`} 
                  target="_blank"> 
                  <Button className="left-align" variant="contained" color="primary">
                    Pay and Extend Ad-Free Game
                  </Button>
                </Link>
              </li>
              <li> 
                <Button className="left-align" variant="outlined" color="primary" onClick={close}>
                  End game and lose progress after timer is up
                </Button>
              </li>
            </ul>
          </div>
        )}
      />
    </span>

  );
}