import React, { useEffect } from 'react';
import HeaderBar from '../../components/appbar';
import PokerRankingsList from '../../components/pokerrankingslist';

export default function() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Winning Hands in Poker`;
  });

  return (
    <main className="page poker-winning-hands">
      <HeaderBar />
      <div className="content">
        <h1>Winning Poker Hands</h1>
        <PokerRankingsList />
      </div>
    </main>
  );
}
