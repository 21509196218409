import React, { useEffect }from 'react';
import postscribe from 'postscribe';
import './index.scss';

export default function() {
  useEffect(() => {
    if (!document.getElementById('ad-id')) {
      postscribe('#medianet', `<script type="text/javascript" id="ad-id">
      amzn_assoc_placement = "adunit0";
      amzn_assoc_tracking_id = "texasholdemwi-20";
      amzn_assoc_ad_mode = "search";
      amzn_assoc_ad_type = "smart";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_default_search_phrase = "Apple";
      amzn_assoc_default_category = "All";
      amzn_assoc_linkid = "3aa1df58fd93fd39f5d6fd9cbb4bc3c0";
      amzn_assoc_design = "in_content";
      </script>
      <script src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US"></script>`);
    }
  });
  
  return (
    <div id="medianet"></div>
  );
}
