import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import HomePage from './pages/home/home';
import PaymentPage from './pages/payment/payment';
import GamePage from './pages/gamepage/gamepage';
import NoGamePage from './pages/nogame';
import Tier from './pages/tier';
import Ad from './pages/ad';
import Lobby from './pages/lobbypage/lobbypage';
import Join from './pages/joinpage';
import JoinQueue from './pages/join-queue';
import TableQueue from './pages/table-queue';
import PokerWinningHands from './pages/pokerhands';
import Terms from './pages/terms';
import FAQ from './pages/faq';
import Contact from './pages/contact';
import LogRocket from 'logrocket';
import './App.scss';
import SupportUs from "./pages/supportus";
LogRocket.init('cxlqpy/texas-hold-em-with-friends');


export default function TexasHoldEm() {
  return (
    <Router>
      <div className="body">
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/lobby/:id">
            <Lobby />
          </Route>
          <Route exact path="/game-with-ads">
            <Ad />
          </Route>
          <Route path="/tier">
            <Tier />
          </Route>
          <Route path="/join/:id">
            <Join />
          </Route>
          <Route path="/payment/:id?">
            <PaymentPage />
          </Route>
          <Route path="/game/:id">
            <GamePage />
          </Route>
          <Route path="/nogame/:id">
            <NoGamePage />
          </Route>
          <Route path="/join-queue">
            <JoinQueue />
          </Route>
          <Route path="/table-queue">
            <TableQueue />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/supportus">
            <SupportUs />
          </Route>
          <Route path="/poker-winning-hands">
            <PokerWinningHands />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
