import React, { useEffect } from "react";
// import './index.css';
import {
  Whatshot,
  DataUsage,
  Block
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import DeckBack from '../cards/deck-back';
import YourHand from '../../components/YourHand';

export default function Opponents(props) {
  const {
    opponentList,
    currentPlayerTurn,
    dealerId,
    smallBlindId,
    bigBlindId,
    manualGamePosition
  } = props;

  useEffect(() => {
  });

  const getPlayerClass = (props) => {
    const {
      uiTableSpot,
      status,
      spotAtTable,
      showHand,
      winner
    } = props.opponent;
    const {currentPlayerTurn} = props;

    let elemClass = `player player0${uiTableSpot}`;

    if ((spotAtTable === currentPlayerTurn) && !manualGamePosition) {
      elemClass = `${elemClass} current-turn`;
    } else if (status === 'FOLD') {
      elemClass = `${elemClass} player-fold`;
    } else if (status === 'BUST') {
      if (manualGamePosition) {
        if (manualGamePosition === 'river') {
          elemClass = `${elemClass} player-bust`;
        }
      } else {
        elemClass = `${elemClass} player-bust`;
      }
    }

    // show cards at end of round to determine winner
    if (showHand) {
      elemClass = `${elemClass} show-cards`;
    }
    
    //if winner, add another class stating winner
    if (winner && !manualGamePosition) {
      elemClass = `${elemClass} winning-hand`;
    } else if (winner && manualGamePosition === 'river') {
      elemClass = `${elemClass} winning-hand`;
    }

    return elemClass;
  };

  const inBetweenManualRound = (gamePosition) => {
    // console.log('.....gamePosition', gamePosition);
    return gamePosition === 'flop' || gamePosition === 'turn' || gamePosition === 'pre-flop'
  };

  const getChipCount = (bankroll, manualGamePosition) => {
    // console.log('<<<<<<<<<<<< manualGamePosition', manualGamePosition)
    if (manualGamePosition && manualGamePosition !== 'river') {
      return (<CircularProgress size={15} />);
    } else {
      return bankroll;
    }
  };

  const GetPlayerStatus = (props) => {
    const {
      bankroll,
      opponentId,
      spotAtTable,
      status
    } = props.opponent;
    const {
      dealerId,
      smallBlindId,
      bigBlindId
    } = props;
    

    // console.log('spot---', spotAtTable);
    // console.log('smallBlindId---', props);

    return (
      <div>
        { smallBlindId === spotAtTable ? <span className="small-blind">SB</span> : null }
        { bigBlindId === spotAtTable ? <span className="big-blind">BB</span> : null }
        { dealerId === spotAtTable ? <Whatshot className="dealer"></Whatshot> : null }
        { bankroll || status !== 'BUST' || inBetweenManualRound(manualGamePosition) ?
        <span className="opponents-total-chips"><DataUsage className="chip-icon"></DataUsage> {getChipCount(bankroll, manualGamePosition)}</span>
        :
        <span className="opponents-total-chips"><Block className="bust-icon"></Block>Bust</span> }
      </div>
    );
  };

  const getBetAmt = (currentBet) => {
    return (<div className="opponent-bet"><span className="bet-amt"><DataUsage className="chip-icon"></DataUsage> {currentBet}</span></div>);
  };


  const getOpponentCards = (opponent = {
    showHand: null
  }) => {
    let {
      showHand
    } = opponent;

    if (!showHand) {
      return (
        <div className="card-couple">
          <DeckBack />
          <DeckBack />
        </div>
      );
    }

    let opponentCards = [
      opponent.carda,
      opponent.cardb
    ]

    return (
      <YourHand
        yourCards={opponentCards}
      />
    ); 
  };

  const opponentElems = opponentList.map((opponent = {
    spotAtTable: null,
    uiTableSpot: null,
    currentTurn: null,
    status: null,
    bankroll: null,
    name: null
  }) => {
    let {
      name,
      uiTableSpot,
      bankroll,
      currentBet = null,
      subtotal_bet = null
    } = opponent;

    return (
      <div className={getPlayerClass({opponent, currentPlayerTurn})} key={uiTableSpot}>
        <div className="opponent-cards">
          { subtotal_bet ? getBetAmt(subtotal_bet) : null } {/* false will be if bet is confirmed */}
          { getOpponentCards(opponent) }
        </div>
        <div className="player-info">
          <span className="name">{name}</span>
          <div className="player-status">
            <GetPlayerStatus
              opponent={opponent}
              dealerId={dealerId}
              smallBlindId={smallBlindId}
              bigBlindId={bigBlindId}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="opponents">
      {opponentElems}
    </div>
  );
}