import React, { useEffect } from 'react';

import DeckBack from '../cards/deck-back';
import AceOfClubs from  '../cards/aceOfClubs';
import AceOfDiamonds from  '../cards/aceOfDiamonds';
import AceOfHearts from  '../cards/aceOfHearts';
import AceOfSpades from  '../cards/aceOfSpades';

import TwoOfClubs from  '../cards/twoOfClubs';
import TwoOfDiamonds from  '../cards/twoOfDiamonds';
import TwoOfHearts from  '../cards/twoOfHearts';
import TwoOfSpades from  '../cards/twoOfSpades';

import ThreeOfClubs from  '../cards/threeOfClubs';
import ThreeOfDiamonds from  '../cards/threeOfDiamonds';
import ThreeOfHearts from  '../cards/threeOfHearts';
import ThreeOfSpades from  '../cards/threeOfSpades';

import FourOfClubs from  '../cards/fourOfClubs';
import FourOfDiamonds from  '../cards/fourOfDiamonds';
import FourOfHearts from  '../cards/fourOfHearts';
import FourOfSpades from  '../cards/fourOfSpades';

import FiveOfClubs from  '../cards/fiveOfClubs';
import FiveOfDiamonds from  '../cards/fiveOfDiamonds';
import FiveOfHearts from  '../cards/fiveOfHearts';
import FiveOfSpades from  '../cards/fiveOfSpades';

import SixOfClubs from  '../cards/sixOfClubs';
import SixOfDiamonds from  '../cards/sixOfDiamonds';
import SixOfHearts from  '../cards/sixOfHearts';
import SixOfSpades from  '../cards/sixOfSpades';

import SevenOfClubs from  '../cards/sevenOfClubs';
import SevenOfDiamonds from  '../cards/sevenOfDiamonds';
import SevenOfHearts from  '../cards/sevenOfHearts';
import SevenOfSpades from  '../cards/sevenOfSpades';

import EightOfClubs from  '../cards/eightOfClubs';
import EightOfDiamonds from  '../cards/eightOfDiamonds';
import EightOfHearts from  '../cards/eightOfHearts';
import EightOfSpades from  '../cards/eightOfSpades';

import NineOfClubs from  '../cards/nineOfClubs';
import NineOfDiamonds from  '../cards/nineOfDiamonds';
import NineOfHearts from  '../cards/nineOfHearts';
import NineOfSpades from  '../cards/nineOfSpades';

import TenOfClubs from  '../cards/tenOfClubs';
import TenOfDiamonds from  '../cards/tenOfDiamonds';
import TenOfHearts from  '../cards/tenOfHearts';
import TenOfSpades from  '../cards/tenOfSpades';

import JackOfClubs from  '../cards/jackOfClubs';
import JackOfDiamonds from  '../cards/jackOfDiamonds';
import JackOfHearts from  '../cards/jackOfHearts';
import JackOfSpades from  '../cards/jackOfSpades';

import QueenOfClubs from  '../cards/queenOfClubs';
import QueenOfDiamonds from  '../cards/queenOfDiamonds';
import QueenOfHearts from  '../cards/queenOfHearts';
import QueenOfSpades from  '../cards/queenOfSpades';

import KingOfClubs from  '../cards/kingOfClubs';
import KingOfDiamonds from  '../cards/kingOfDiamonds';
import KingOfHearts from  '../cards/kingOfHearts';
import KingOfSpades from  '../cards/kingOfSpades';

const cardMapping = {
  c2: <TwoOfClubs />,
  c3: <ThreeOfClubs />,
  c4: <FourOfClubs />,
  c5: <FiveOfClubs />,
  c6: <SixOfClubs />,
  c7: <SevenOfClubs />,
  c8: <EightOfClubs />,
  c9: <NineOfClubs />,
  c10: <TenOfClubs />,
  c11: <JackOfClubs />,
  c12: <QueenOfClubs />,
  c13: <KingOfClubs />,
  c14: <AceOfClubs />,
  d2: <TwoOfDiamonds />,
  d3: <ThreeOfDiamonds />,
  d4: <FourOfDiamonds />,
  d5: <FiveOfDiamonds />,
  d6: <SixOfDiamonds />,
  d7: <SevenOfDiamonds />,
  d8: <EightOfDiamonds />,
  d9: <NineOfDiamonds />,
  d10: <TenOfDiamonds />,
  d11: <JackOfDiamonds />,
  d12: <QueenOfDiamonds />,
  d13: <KingOfDiamonds />,
  d14: <AceOfDiamonds />,
  h2: <TwoOfHearts />,
  h3: <ThreeOfHearts />,
  h4: <FourOfHearts />,
  h5: <FiveOfHearts />,
  h6: <SixOfHearts />,
  h7: <SevenOfHearts />,
  h8: <EightOfHearts />,
  h9: <NineOfHearts />,
  h10: <TenOfHearts />,
  h11: <JackOfHearts />,
  h12: <QueenOfHearts />,
  h13: <KingOfHearts />,
  h14: <AceOfHearts />,
  s2: <TwoOfSpades />,
  s3: <ThreeOfSpades />,
  s4: <FourOfSpades />,
  s5: <FiveOfSpades />,
  s6: <SixOfSpades />,
  s7: <SevenOfSpades />,
  s8: <EightOfSpades />,
  s9: <NineOfSpades />,
  s10: <TenOfSpades />,
  s11: <JackOfSpades />,
  s12: <QueenOfSpades />,
  s13: <KingOfSpades />,
  s14: <AceOfSpades />,
  xx: <DeckBack />
};

export default function SingleCard(props) {
  const { cardId } = props;

  const Card = () => {
    return cardMapping[cardId];
  }

  return (
    <Card />
  );
}