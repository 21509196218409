import React,  { useEffect } from "react";
import './index.scss';
import HeaderBar from '../../components/appbar';

export default function FAQ() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - FAQs`;
  });

  return (
    <main className="page faq-page">
      <HeaderBar />
      <div className="content">
        <h1>Frequently Asked Questions</h1>
        <ul>
            <li>
              <p><strong>Q: What is Texas HoldEm With Friends ?</strong></p>
              <p><strong>A:</strong> Texas HoldEm With Friends is the easiest way to play Poker instantly with your friends, free of app downloads, no sign ups required, and free of charge.</p>
            </li>
            <li>
              <p><strong>Q: How many friends can I play with?</strong></p>
              <p><strong>A:</strong> You can play with up to 8 friends for a 9 person game.</p>
            </li>
            <li>
              <p><strong>Q: How do I host multiple games at the same time?</strong></p>
              <p><strong>A:</strong> You can easily create another game and lobby where you can invite another group of friends to play with. Just go to the home page and click on game table.</p>
            </li>
            <li>
              <p><strong>Q: How can I join a friend's game? </strong></p>
              <p><strong>A:</strong> When the host creates a game, they will get a unique url that they can share with their friends. Once you click on this URL you'll be given the option to join the table with your name.</p>
            </li>
            <li>
              <p><strong>Q: Can I play with random players? </strong></p>
              <p><strong>A:</strong> No, Texas Hold Em With Friends was made specifically to play with your friends. If we get enough feature requests for this, we may consider adding this to our backlog.</p>
            </li>
            <li>
              <p><strong>Q: How long do me and my friends have to finish the Texas Hold Em game?</strong></p>
              <p><strong>A:</strong> You have 30 days to complete the game with your friends. If the game isn't completed within 30 days, you're table will be deleted. </p>
            </li>
            <li>
              <p><strong>Q: Can I play for money with my friends?</strong></p>
              <p><strong>A:</strong> No, that would be gambling.</p>
            </li>
            <li>
              <p><strong>Q: Can players "buy-in" again after they go bust?</strong></p>
              <p><strong>A:</strong> At this time we have not created this feature yet, but it's in the backlog.</p>
            </li>
            <li>
              <p><strong>Q: How do I increase the blinds?</strong></p>
              <p><strong>A:</strong> At the moment you can only set the blinds once at the beginning of the game, we have a feature in our backlog to allow the host to change the blinds during the game.</p>
            </li>
            <li>
              <p><strong>Q: How are the cards shuffled? </strong></p>
              <p><strong>A:</strong> Cards are shuffled in a random order at the beginning of the game. They are then drawn and each player is given 1 card in each seat until each player gets 2 cards. At the end of each game, we collect the cards, and reshuffle them. The shuffling algorithm has no knowledge about poker hands or players it’s dealing to. The players and results are determined separately once the hand is complete.</p>
            </li>
            <li>
              <p><strong>Q: Is there any bias or any change of rigged cards towards players? </strong></p>
              <p><strong>A:</strong> No the shuffling algorithm takes care of this and can not be altered by an player.</p>
            </li>
            <li>
              <p><strong>Q: How secure is Texas Hold Em With Friends? </strong></p>
              <p><strong>A:</strong> A player's hand is only dealt to that player and device only. No one else has access to view these cards unless they are shown if both players go all-in or cards must be shown to determine the winner of the round.</p>
            </li>
            <li>
              <p><strong>Q: Can I continue the game on a different device?</strong></p>
              <p><strong>A:</strong> No, once you have started a game on that device, you can not switch to another device. If enough feature requests are made, we may add the ability to another device in the future.</p>
            </li>
            <li>
              <p><strong>Q: Where can I report bugs or get assistance?</strong></p>
              <p><strong>A:</strong> You can contact us at <a href="mailto:&#116;&#101;&#120;&#097;&#115;&#119;&#105;&#116;&#104;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#112;&#111;&#107;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">texaswithfriendspoker@gmail.com</a>. We will make sure that your issue gets reviewed by the team.</p>
            </li>
            {/* <li>
              <p><strong>Q: Where can I donate to the project?</strong></p>
              <p><strong>A:</strong> You can donate to the Texas Hold Em With Friends project at the following Patreon link</p>
            </li> */}
          </ul>
      </div>
    </main>
  );
}