import React,  { useEffect, useState} from "react";
import  './home.scss';
import {
  Link
} from "react-router-dom";
import {
  Grid,
  Button,
  Box
} from '@material-ui/core';
import MediaNetAd from '../../components/mediaNetAd';
import HeaderBar from '../../components/appbar';
// import DonateButton from "../../components/DonateButton";

export default function HomePage() {
  useEffect(() => {
    // @TODO Use React Helmet
    window.document.title = `Texas Hold Em With Friends`;
  });

  return (
    <div className="page home-page">
      <HeaderBar />
      <div className="content">

        <MediaNetAd />
          <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <p>Free.<br />No sign up required.<br />No app download.</p>

            <Link to="/tier">
              <Button variant="contained" color="primary">Get your table!</Button>
            </Link>

            <div className={'donate-container'}>
              {/* <DonateButton 
              variation="big"/> */}
            </div>
          </Grid>
        </Grid>
        {/* <Box mt={3}>
          <Banner />
        </Box> */}
      </div>
    </div>
  );
}