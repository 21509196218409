import {
  useHistory
} from "react-router-dom";
import { auth } from '../../store';
import fetch$ from '../../utils/fetch';
import './payment.scss';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import WarningModal from '../../components/WarnModal';

import {
  Grid,
  Select,
  MenuItem,
  makeStyles,
  FormControl
} from '@material-ui/core';
import { PayPalButton } from "react-paypal-button-v2";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white',
    padding: '0 4px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Payment() {
  const classes = useStyles();
  const [hrs,setHrs] = React.useState(1);
  const [isOpen, setModal] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const { id } = useParams();
  const history = useHistory();

  const handleHrChange = event => {
    let hrsSelected = event.target.value;
    setHrs(hrsSelected);
    calcTotal(hrsSelected)
  };

  const calcTotal = hrs => {
    let total = hrs * 1;
    setTotal(total);
  };

  useEffect(() => {
    calcTotal(hrs);
  });

  const close = () => {
    setModal(false)
  };

  return (
    <div className="pay-page">
      {id && <WarningModal 
         open={isOpen}
         close
         title='Confirmation'
         message='Thank you for extending Ad free gaming. This tab will close in 5 seconds.'
      />}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <h1 className="pay-title">Reserve your table</h1>
          <h3 className={"pay-subtitle"}>Choose hours</h3>
          <FormControl className={classes.formControl}>
            <Select
              labelId="set-hours-label"
              id="set-hours-select"
              value={hrs}
              onChange={handleHrChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>
          <h3 className="total">Total <span>${total}</span></h3>
        </Grid>
        <Grid className="paypal-opts" item xs={12}>
          <PayPalButton
            // amount={.01} // @NOTE: for testing
            amount={total}
            shippingPreference="NO_SHIPPING"
            onSuccess={(details, data) => {
              let uid;

              // NOTE: authenticate the user
              auth.signInAnonymously().catch((error) => {
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log(errorCode, errorMessage);
              });

              auth.onAuthStateChanged(async (user) => {
                if (user) {
                  // listens that the anonymouse authentication has occured
                  const isAnonymous = user.isAnonymous;
                  uid = user.uid;
                  // console.log('>>>isAnonymous', isAnonymous);
                  // console.log('---uid', uid);
                  // console.log('------------', id);
                  if(!id) {
                    // NOTE: After authentication, save the transaction
                    const resp = await fetch$('savetransaction', 'POST', {
                      body: JSON.stringify({
                        orderID: data.orderID,
                        hrs,
                        uid
                      })
                    });
                    
                    history.push(`/lobby/${resp.gameId}`);
                  } else {
                    await fetch$('extendHours', 'POST', {
                      body: JSON.stringify({
                        orderID: data.orderID,
                        hrs,
                        uid,
                        id
                      })
                    });
                
                    setModal(true);
                    setTimeout(() => {
                      setModal(false)
                      window.close();
                    }, 3000);
                  }
                } else {
                  // User is signed out.
                  // ...
                }
              });
            }}
            onError={(e) => {
              console.log(e) // Log Rocket
            }}
            catchError={(e) => {
              console.log(e); // Log Rocket
            }}
            options={
              {
                // DEV clientId: 'AZkr2FquFE1oCcmrggCOOEp6qiA20Lh6vY_q25X6tQyaevipuHbepRqAPe4N3wORF142RaKpkH0EWsSR'
                clientId: 'AaFIoEAvOByR2PG_ikBOg1s6B1o2Vbc9FdI-zYP3NM9Ed8DzgToErPoE-3k5dpzV0o1WOmQz_txgnoLi'
                // @NOTE: PROD clientId KEY: 
                // @NOTE: SANDBOX clientId KEY: AZkr2FquFE1oCcmrggCOOEp6qiA20Lh6vY_q25X6tQyaevipuHbepRqAPe4N3wORF142RaKpkH0EWsSR
              } // @TODO Move to a CONFIG FILE
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}