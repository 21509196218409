import fetch$ from '../../../utils/fetch';

const postYourBet = async ({
  betAmt = 0,
  status = 'active',
  currentPlayerTurn,
  typeOfPlay,
  gameId = 'MQ==',
}) => {
  // console.log('--betAmt', betAmt);
  // console.log('--status', status);

  // this will post to DB, and UI will auto-rerender through firebase 
  await fetch$('setplay', 'POST', {
    body: JSON.stringify({
      gameId,
      betAmt,
      currentPlayerTurn,
      typeOfPlay
    })
  });
};

export default postYourBet;
