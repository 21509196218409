import React, { useEffect }  from "react";
import  './index.scss';
import {
  Grid,
  Button
} from '@material-ui/core';
import HeaderBar from '../../components/appbar';
import AceOfClubs from '../../components/cards/aceOfClubs';
import AceOfSpades from '../../components/cards/aceOfSpades';
import AceOfDiamonds from '../../components/cards/aceOfDiamonds';
import AceOfHearts from '../../components/cards/aceOfHearts';
import DonateButton from "../../components/DonateButton";

export default function SupportUs() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Support Us`;
  });

  return (
    <main className="page">
      <HeaderBar />

      <div className="content contact-content">
        <h1>Become an official supporter on Patreon!</h1>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <p>
              <h3>Be the first to support us!</h3>
              <DonateButton 
              variation="big"/>

              {/* <div className={"aces"}>
                <h2>Official supporters:</h2>
                <ul>
                  <li>A-Rod</li>
                  <li>Badman Rodriguez</li>
                </ul>
              </div> */}
            </p>
          </Grid>
        </Grid>
      </div>
    </main>
  );
}