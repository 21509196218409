import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  TextField,
  Box
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {auth} from '../../store';
import { fetch$ } from '../../utils';
import { useDispatch } from 'react-redux';
import {
  SentimentVerySatisfied
} from '@material-ui/icons';
import './index.scss';

export default function JoinQueue () {
  const { id } = useParams();
  const history = useHistory();
  const [ name, setName ] = useState('');
  const updateName = (e) => setName(e.target.value);
  const dispatch = useDispatch();
  const [joinClicked, setJoinClicked] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'show',
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  }));
  useFirestoreConnect([
    {
      collection: `games`
    }
  ]);
  const classes = useStyles();
  const gamesLength = useSelector(
    ({ firestore: { data } }) => {
      // console.log('====>games', data.games);
      if (data.games) {
        return data.games.length;
      }
    }
  );
  const uid  = useSelector((state) => state.firebase.auth.uid);

  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Join Queue`;

    return () => { document.body.className = ''; }
  });

  const joinQueue = async (e) => {
    setJoinClicked(!joinClicked);

    e.preventDefault();

    let resp = await fetch$('addToQueue', 'POST', {
      body: JSON.stringify({
        uid
      })
    });

    history.push('/table-queue');
  }

  return (
    <div className="lobby">
      <h1>No Tables Available</h1>
      <p>During this BETA period we are limiting amount of tables for game play. Add your name to the wait list to grab the next open table.</p>

      <p>Don't want to wait? Get an ad-free table for your friends.</p>

      <div className="guest-checkin">
        <div className="guest-name">
          <FormControl>
            <TextField
              id="standard-helperText"
              label="Enter your name"
              onChange={updateName}
              variant="outlined"
            />
          </FormControl>
        </div>
        <div>
          <Button variant="contained" color="primary" disabled={joinClicked} onClick={joinQueue}>{joinClicked ? 'Joining Queue...' : 'Add Me in Line'}</Button>
        </div>
      </div>
    </div>
  );
}