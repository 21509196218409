import React, { useEffect } from 'react';
import HeaderBar from '../../components/appbar';

export default function() {
  useEffect(() => {
    window.document.title = `Texas Hold Em With Friends - Terms and Conditions`;
  });

  return (
    <main className="page tier">
      <HeaderBar />
      <div className="content">
        <h1>Terms of Service</h1>
        <p>
          By accessing texaswithfriendspoker.com (the "Site") you are indicating your agreement to be bound
          by these Terms of Service. If you do not agree to be bound by these Terms, disconnect from the Site immediately.
          If you have any questions, please contact us at: <a href="mailto:&#116;&#101;&#120;&#097;&#115;&#119;&#105;&#116;&#104;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#112;&#111;&#107;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">texaswithfriendspoker@gmail.com</a>. 
        </p>
        <h3>1. DESCRIPTION OF THE SERVICE </h3>
        <p>
          1.1 Cubed A Co. (also referred to in these terms as "we" or "us") will provide
          you with access to the Site. Access to the Site is free. Some other services 
          provided by Replay Gaming may not be free. If this is the case, Cubed A Co. 
          will inform you before you pay for the other services that they are not free and 
          make sure that it is clear what the charges are.
        </p>
        <p>
          1.2 In these Terms of Sevice, Service refers to any services provided by us, including the provision of access to the Site.
        </p>
        <h3>2. ACCESS TO OUR SERVICE</h3>
        <p>
          2.1 We make no promise that our Service will be uninterrupted or error free.
        </p>
        <p>
          2.2 Your access to the Service may be occasionally restricted to allow for repairs, 
          maintenance or the introduction of new facilities or services. Replay Gaming will attempt 
          to restore the Service as soon as it reasonably can.
        </p>
        <p>
          2.3 Because of the nature of the Internet, the Service is provided on an "as is" and "as available" basis.
          We are not responsible to you for any error, omission, interruption, defect, delay in operation or transmission
        or other failure to provide the Service which results from a reason beyond our control.
        </p>
        <p>
          2.5 ReplayPoker.com is an adult site intended for players age 13 and over. Players under the age of 13 are not allowed to access the site.
        </p>
        <h3>3. LINKS TO OTHER WEB SITES AND SERVICES</h3>
        <p>
          3.1 The Site may contain links to other web sites which are not under the control of and are not maintained by us. 
          These links are provided for your convenience only. and we are not responsible for the contents or security of those sites.
        </p>
        <p>
          3.2 We shall not be responsible for any loss or damage suffered by you as a result of using the links provided on our Site.
        </p>
        <h3>4. CONTENT OF THE SITE</h3>
        <p>
          4.1 The content made available in the Site is provided "as is" without warranties of any kind. Because of the nature 
          of the information displayed on the Site, Cubed A Co. cannot verify whether it is accurate or up to date. 
          If Cubed A Co. becomes aware that the content is inaccurate or out of date it will endeavour to remove it from the Site or 
          take other appropriate action as soon as is reasonably possible, but it accepts no legal obligation to do so.
        </p>
        <p>
          4.2 Cubed A Co. does not warrant that the content on the Site will be free of errors or viruses or that it will not be defamatory or offensive.
        </p>
        <p>
          4.3 Cubed A Co. does not warrant, represent or endorse the accuracy or reliability of any of the information, content, advertisements or any 
          third party site and content contained on, distributed through or linked, downloaded or accessed from the Site, nor the quality of any products, services, 
          information or other materials displayed, purchased or obtained by you as a result of an advertisement or any other information or offer on or in connection with the Site or Replay Gaming.
        </p>
        <h3>5. USE OF PLAY CHIPS</h3>
        <p>
          5.1 Chips is a virtual currency used in games.
        </p>
        <p>
          5.2 Play Money is for entertainment purposes only and are not redeemable or transferable for real-world funds. 
          Play chips is not redeemable for any amount of real world currency or monetary value at any time.
        </p>
        <p>
          5.3 You may not sell your Play chips to any person, company or entity.
        </p>
        <h3>6. COPYRIGHT / TRADE MARKS</h3>
        <p>
          6.1 All rights in the HTML, designs, programming and information posted by us on the Site are owned by Cubed A Co. or its licensors. 
          You may not copy, reproduce, modify, distribute, republish, display, post or transmit any part of this Site without our permission.
        </p>
        <p>
          6.2 All trade marks, service marks, and trade names displayed on the Site are the marks of the owners of the relevant material, 
          and may only be used by you as permitted by law or as expressly authorised by the owner.
        </p>
        <h3>7. LIABILITY</h3>
        <p> 7.1 We will not be liable for your loss of profits, wasted expenditure, corruption or destruction of data or any other loss
          which does not directly result from something we have done wrong, even if we are aware of the possibility of such damage arising.
        </p>
        <p>
          7.2 Cubed A Co. expressly disclaims any and all warranties, including without limitation, any warranties as to the availability, 
          accuracy, or content of information, products, or services, or any terms, conditions or warranties of quality, merchantability or fitness of the content on the Site for a particular purpose.
        </p>
        <h3>8. DATA PROTECTION</h3>
        <p>
          8.1 We will respect your personal data and will comply with all applicable US data protection legislation currently in force.
        </p>
        <p>
          8.2 We will not use or store your personal information for any purposes.
        </p>
        <h3>9. GENERAL</h3>
        <p>
          9.1 If any of these Terms of Service are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, 
          such term, condition or provision will to that extent, be severed from the remaining terms, conditions and provisions which 
          shall continue to be valid to the fullest extent permitted by law.
        </p>
        <p>
          9.2 Cubed A Co. reserves the right to modify these Terms of Service at any time by the posting of modified Terms of Service on this website.
        </p>
      </div>
    </main>
  );
}
