import React, { useEffect } from "react";
import './game.scss';
import {
  Grid,
  makeStyles,
  Modal,
  Button,
  Slider,
  OutlinedInput,
  CircularProgress,
} from '@material-ui/core';
import {
  Done,
  KeyboardArrowUp,
  Clear,
  DoneOutline,
  ArrowForwardIos,
  DataUsage
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Table from '../../components/table';
import Loader from '../../components/loader';
import Timer from '../../components/timer/timer';
import Banner from '../../components/bannerAd';
import mockGameData from '../../mockdata/gamedata';
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import fetch$ from '../../utils/fetch';
import Countdown from 'react-countdown';
import PokerRankingModal from '../../components/PokerRankingsModal';
import {
  getYourTableData,
  getOpponents,
  getYourBet,
  postYourBet,
  bet, 
} from './lib';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const GAME_TEST_ID = 'MQ==';

export default function GamePage() {
  const history = useHistory();
  const { id } = useParams();
  const [uidCounter, setUidCounter] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [showPokerRankingModal, setShowPokerRankingModal] = React.useState(false);
  const [inBetweenRound, setInBetweenRound] = React.useState(false);
  const [inBetweenManualRound, setInBetweenManualRound] = React.useState(false);
  const [manualModalCounter, setManualModalCounter] = React.useState(0);
  const [isRoundOver, setIsRoundOver] = React.useState(false);
  const [manualGamePosition, setManualGamePosition] = React.useState('');
  const [yourCards, setYourCards] = React.useState([]);
  const [raiseVal, setRaiseVal] = React.useState('default');
  const [betAmt, setBetAmt] = React.useState(0);
  const [turnStatus, setTurnStatus] = React.useState('unknown');
  const [displayRaiseOpts, setDisplayRaiseOpts] = React.useState(false);
  const classes = useStyles();
  const toggle = (boolValue) => setOpen(boolValue);
  const getMinRaiseVal = (game) => game.currentBetAmt + game.currentMinRaise;
  const createRaiseMarks = (game) => {
    let maxVal = getYourTableData(game.players, uid).bankroll;
    let minVal = getMinRaiseVal(game);

    return [
      {
        value: minVal,
        label: String(minVal),
      },
      {
        value: maxVal,
        label: String(maxVal),
      },
    ];
  }
  const updateRaiseValue = (evt, value) => setRaiseVal(value);

  useFirestoreConnect([
    {
      collection: `games`,
      doc: id
    }
  ]);
  const uid  = useSelector((state) => state.firebase.auth.uid);

  let prevGamePosition = null;

  const checkIfBetweenRounds = (prevGamePosition, gamePosition) => {
    if (prevGamePosition === 'pre-flop' && gamePosition === 'flop' ||
    prevGamePosition === 'flop' && gamePosition === 'turn' ||
    prevGamePosition === 'turn' && gamePosition === 'river') {
      setInBetweenRound(true);

      // clear modal
      setTimeout(() => {
        setInBetweenRound(false);
      }, 5000);
    }
  };

  const getGamePositionName = (prevPosition) => {
    let position = '';

    switch(prevPosition) {
      case 'pre-flop':
        position = 'flop';
        break;
      case 'flop':
        position = 'turn';
        break;
      case 'turn':
        position = 'river';
        break;
      case 'river':
        position = 'round-over';
        break;
      default:
        break;
    }
    return position;
  };

  let prevManualGamePosition = null;
  const getGameManualPosition = (prevPosition, gamePosition) => {
    let newGamePosition;

    // if round or game is over, set flag to show other cards slowly
    if (gamePosition === 'round-over' || gamePosition === 'game-over') {
      setIsRoundOver(true);
      newGamePosition = getGamePositionName(prevPosition);
    }

    // console.log('newGamePosition', newGamePosition);
    return newGamePosition;
  };

  const checkIfJumpedToEndOfGameOrRound = (prevPosition, gamePosition) => {
    const isEndOfRoundOrGame = gamePosition === 'round-over'|| gamePosition === 'game-over';

    // this means we jumped to the end of the game
    if (prevPosition === 'pre-flop' && isEndOfRoundOrGame ||
    prevPosition === 'flop' && isEndOfRoundOrGame ||
    prevPosition === 'turn' && isEndOfRoundOrGame) {
      let manGamePosition = getGameManualPosition(prevPosition, gamePosition);
      // console.log('checkGameManualPosition', manGamePosition);
      prevManualGamePosition = prevPosition;
      setManualGamePosition(prevPosition);

    } else if (gamePosition === 'pre-flop'
      && (prevPosition === 'round-over' 
      || prevPosition === 'game-over')) {
      prevGamePosition = 'round-over';

      // reset manual states to defaults in case users go all in again...
      setManualGamePosition('');
      setInBetweenManualRound(false);
      setManualModalCounter(0);
    }
  }

  const game = useSelector(
    ({ firestore: { data } }) => {
      let game = data.games && data.games[id];
  
      if (game) {
        // console.log('prevGamePosition-------', prevGamePosition);
        // console.log('manualGamePosition--------------', manualGamePosition);

        // this will fetch your new cards after the round is over.
        if ((prevGamePosition === 'round-over' || prevGamePosition === 'round-over-fold') && game.gamePosition === 'pre-flop') {
          // reset manual states to defaults in case users go all in again...
          prevGamePosition = null;
          prevManualGamePosition = null;
          setManualGamePosition('');
          setInBetweenManualRound(false);
          setManualModalCounter(0);
          setYourCards([]);

          setTimeout(() => {
            fetchOwnGame();
          }, 500);
        }

        // check if round or game is over. This will determine cards to be shown slowly if players go all in.
        checkIfJumpedToEndOfGameOrRound(prevGamePosition, game.gamePosition);

        // check if in between rounds
        checkIfBetweenRounds(prevGamePosition, game.gamePosition);

        // set your data in variable to be read everywhere
        prevGamePosition = game.gamePosition;

        // check if minimum raise value was set, if not, then 
        if (raiseVal === 'default') {
          setRaiseVal(getMinRaiseVal(game));
        }
      } else {
        if(isLoaded(game)) {
          if (isEmpty(game)) {
            history.push(`/nogame/${id}`);
          }
        }
      }

      return game;
    }
  )
  
  const checkIfOwner = (gameProp) => {
    if (gameProp && gameProp.organizerId) {
      if (window.atob(gameProp.organizerId) === uid) {
        return true;
      }

      return false;
    }
    return false;
  };

  const fetchOwnGame = () => {
    fetch$('getowngame', 'POST', {
      body: JSON.stringify({
        gameId: id,
        yourId: window.btoa(uid)
      })
    }).then(resp => {
      let cardsFromDb = resp.yourCards && resp.yourCards.carda ? 
        [resp.yourCards.carda, resp.yourCards.cardb] : 
        ['xx', 'xx'];
        setYourCards(cardsFromDb);
        setUidCounter(uidCounter + 1);
    });
  };

  useEffect(() => {
    // @TODO REACT HELMET
    window.document.title = `Texas Hold Em With Friends - Playing with friends`;

    // FETCH OWN CARDS ONCE
    if (uid && uidCounter < 1) {
      fetchOwnGame();
    }

    // if this variable is true, this means that we jumped to end of game because all players have went ALL-IN and no more bets can be made.
    // We must make the game more suspensful and allow a few seconds between, the flop, the turn and the river.
    // console.log('------- JUMPING INTO manualGamePosition ROUND -----------', manualGamePosition)
    if (manualGamePosition === 'pre-flop' || manualGamePosition === 'flop' || manualGamePosition === 'turn') {
      // console.log('>>>>>>>>manualModalCounter', manualModalCounter);
      if(manualModalCounter <= 0) {
        setInBetweenManualRound(true); // sets to TRUE (to show modal)
      } else {
        setTimeout(() => {
          // console.log('getting in else....?');
          setInBetweenManualRound(true); // show modal after user has seen the cards on the board for 2.5 seconds
        }, 2500);
      }

      setTimeout(() => {
        // console.log('hiding MODAL');
        setInBetweenManualRound(false); // sets to FALSE (to hide modal)
      }, 5000);

      // AFTER 8 seconds change the type of card. This will give us 4 seconds to switch between round and show the MODAL
      setTimeout(() => {
        setManualModalCounter(manualModalCounter + 1);

        let manGamePosition = getGamePositionName(manualGamePosition);
        prevManualGamePosition = manGamePosition;
        // console.log('manGamePosition', manGamePosition);
        setManualGamePosition(manGamePosition);
      }, 7000);
    }

    return () => { document.body.className = ''; }
  }, [uid, manualGamePosition]);

  const allIn = () => {
    const tableData = getYourTableData(game.players, uid);
    const currentPlayerTurn = game.currentPlayerTurn

    bet({
      type:'allin',
      tableData,
      currentPlayerTurn,
      gameId: id || GAME_TEST_ID
    }, setBetAmt, setTurnStatus, setRaiseVal, setDisplayRaiseOpts);
  };

  const setCall = () => {
    const tableData = getYourTableData(game.players, uid);
    const currentPlayerTurn = game.currentPlayerTurn;

    bet({
      type:'call',
      tableData,
      currentPlayerTurn,
      game,
      gameId: id || GAME_TEST_ID,
    }, setBetAmt, setTurnStatus, setRaiseVal, setDisplayRaiseOpts);
  };

  const setRaise = () => {
    const tableData = getYourTableData(game.players, uid);
    const currentPlayerTurn = game.currentPlayerTurn;
    let minRaise = getMinRaiseVal(game);
    let raiseAmt = minRaise > raiseVal ? minRaise : raiseVal;

    bet({
      type:'raise',
      tableData,
      currentPlayerTurn,
      game,
      gameId: id || GAME_TEST_ID,
      raiseVal: raiseAmt
    }, setBetAmt, setTurnStatus, setRaiseVal, setDisplayRaiseOpts);
  };

  const foldCards = (testVal) => {
    const currentPlayerTurn = game.currentPlayerTurn;
    const tableData = getYourTableData(game.players, uid);

    // game table will automatically add status that they have fold and update UI
    if (testVal !== 'test') {
      setTurnStatus('complete'); 
    }

    bet({
      type:'fold',
      tableData,
      currentPlayerTurn,
      game,
      gameId: id || GAME_TEST_ID,
      raiseVal: raiseVal || getMinRaiseVal(game)
    }, setBetAmt, setTurnStatus, setRaiseVal, setDisplayRaiseOpts);
  };

  const yourBet = (game) => {
    const tableData = getYourTableData(game.players, uid);

    return getYourBet({
      tableData,
      betAmt,
      turnStatus,
      gameId: id || GAME_TEST_ID
    });
  };

  const handleInputChange = (event) => {
    setRaiseVal(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (raiseVal < getMinRaiseVal(game)) {
      setRaiseVal(getMinRaiseVal(game));
    } else if (raiseVal > getYourTableData(game.players, uid).bankroll) {
      setRaiseVal(getYourTableData(game.players, uid).bankroll);
    }
  };

  const getDealTypeTxt = () => {
    let txt = '';
    if (manualGamePosition) {
      switch(manualGamePosition) {
        case 'pre-flop':
          txt = 'May the flop be with you. Dealing the flop...';
          break;
        case 'flop':
          txt = 'Going down the fourth street. Dealing the turn...';
          break;
        case 'turn':
          txt = 'Dealing the river. Good luck!';
          break; 
        default:
      }
    } else {
      switch(game.gamePosition) {
        case 'flop':
          txt = 'May the flop be with you. Dealing the flop...';
          break;
        case 'turn':
          txt = 'Going down the fourth street. Dealing the turn...';
          break;
        case 'river':
          txt = 'Dealing the river. Good luck!';
          break; 
        default:
          //
      }
    }
    return txt;
  };

  const isRaiseDisabled = () => {
    let yourData = getYourTableData(game.players, uid);
    if (game.currentBetAmt >= yourData.bankroll) {
      return true; 
    }
    return false;
  };

  const countdownRenderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div>Next round loading... <CircularProgress color={"#fff"} size={15}  /></div>;
    } else {
      // Render a countdown
      return <div>Next round starting in <span>{seconds} seconds</span></div>;
    }
  };

  const getCurrentRaiseAmt = () => {
    let minRaise = getMinRaiseVal(game);
    let raiseAmt = minRaise > raiseVal ? minRaise : raiseVal;
    return raiseAmt 
  };

  const closePokerRanking = () => {
    setShowPokerRankingModal(false);
  }

  const showPokerModal = () => {
    setShowPokerRankingModal(true);
  };

  const getCallCheckTxt = () => {
    let yourData = getYourTableData(game.players, uid);
    if (game.currentBetAmt === yourData.subtotal_bet) {
      return 'Check';
    }
    let callDifference = game.currentBetAmt - yourData.subtotal_bet;
    return (<span className="call-amt-txt">
      Call <DataUsage className="chip-icon"></DataUsage> {callDifference}
      </span>);
  };

  return (
    <div className="game-page">
      {
        !isLoaded(game) ? <div className="loading-game-table"><Loader /> </div>: null
      }
      {
        isLoaded(game) ? isEmpty(game) ? <Redirect to={`/nogame/${id}`} /> : <div>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={(game.winningTxt && !manualGamePosition) 
            || (manualGamePosition === 'river' 
            || manualGamePosition === 'round-over' 
            || manualGamePosition === 'game-over')}
          onClose={() => toggle(false)}
          className="winner-modal-container"
        >
          <div className="winner-modal">
            <div>
              {game.winningTxt && game.winningTxt.map((txt, key) => (
                <h2 key={key}>{txt}</h2>
              ))}
              {/* <h2>{game.winningTxt}</h2> */}
              {game.gamePosition === 'game-over' ? <p>Game over, thanks for playing.</p> : <p><Countdown date={Date.now() + 12000} renderer={countdownRenderer}/></p>}
              {/* <Banner /> */}
            </div>
          </div>
        </Modal>

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={inBetweenRound || inBetweenManualRound}
          onClose={() => toggle(false)}
          className="round-txt-modal"
        >
          <div className="next-round-modal">
            <div>
              <h2>{getDealTypeTxt()}</h2>
              {/* <Banner /> 
              ^ 8 out of 10 people i played with, hate this 5/25/2020. Not including myself */}
            </div>
          </div>
        </Modal>

        <PokerRankingModal 
          open={showPokerRankingModal}
          close={closePokerRanking}
        />

        <div className="game-table">
          <header>
            <div className="header-content">
              <span className="poker-rankings-btn" onClick={showPokerModal}><ArrowForwardIos /> Ways to win</span>

              {game.type === 'paid' &&
              <Timer
                timerStart={game.timerStart}
                hrs={game.hrs}
                tier={'paid'}
                owner={checkIfOwner(game)}
              />}
            </div>
          </header>
          <Table
            manualGamePosition={manualGamePosition}
            inBetweenManualRound={inBetweenManualRound}
            inBetweenRound={inBetweenRound}
            gamePosition={game.gamePosition}
            board={game.board || []}
            yourTurnStatus={turnStatus}
            yourGameData={getYourTableData(game.players, uid)}
            yourCards={yourCards}
            retryOwnCards={fetchOwnGame}
            opponents={game.players ? getOpponents(game.players, uid) : mockGameData.playersAtTable}
            bigBlindId={game.bigBlindId}
            smallBlindId={game.smallBlindId}
            dealerId={game.dealerId}
            currentPlayerTurn={game.currentPlayerTurn} // arbitrary number to avoid gloving that you are selected
            currentPot={game.currentPotSize || 12 /* arbitrary number to show in UI when not working local*/}
            yourBet={yourBet(game)}
          />

          { !manualGamePosition && (game.currentPlayerTurn === getYourTableData(game.players, uid).spotAtTable && turnStatus !== 'complete') ? <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="table-btn-actions"
          >
            <Grid item xs={3}>
              <Button disabled={displayRaiseOpts} variant="contained" fullWidth={true} color="secondary" onClick={foldCards}><Clear></Clear> Fold</Button>
            </Grid>
            <Grid item xs={5}>
              <Button disabled={displayRaiseOpts} variant="contained" fullWidth={true} color="primary" onClick={setCall}><Done></Done>{getCallCheckTxt()}</Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" fullWidth={true} disabled={isRaiseDisabled()} className={displayRaiseOpts ? 'btn-raise raising' : 'btn-raise'} onClick={() => setDisplayRaiseOpts(!displayRaiseOpts)}><KeyboardArrowUp></KeyboardArrowUp>Raise</Button>
            </Grid>

            {
              displayRaiseOpts ?
              <div className="raise-actions">
                <div className="raise-select-amount">
                  <div className="raise-slider">
                  <Slider
                    value={typeof raiseVal === 'number' ? raiseVal : 0}
                    onChange={updateRaiseValue}
                    aria-labelledby="input-slider"
                    min={getMinRaiseVal(game)}
                    max={getYourTableData(game.players, uid).bankroll}
                    marks={createRaiseMarks(game)}
                  />
                  </div>
                  <div class="raise-input-manual">
                    <OutlinedInput
                        className={`${classes.input} raise-input-txt`}
                        value={raiseVal}
                        variant="outlined"
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                          step: 1,
                          min: getMinRaiseVal(game),
                          max: getYourTableData(game.players, uid).bankroll,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                  </div>
                </div>
                <div className="confirm-bet-btns">
                  <Button variant="contained" color="secondary" onClick={setRaise} className="raise-by-amt">BET <span>{getCurrentRaiseAmt(game)}</span> <DoneOutline className="raise-icon"></DoneOutline></Button>
                  <Button variant="contained" color="secondary"  onClick={allIn} className="raise-all-in">All In <DoneOutline className="raise-icon"></DoneOutline></Button>
                </div>
                </div>
              : null
            }
            
          </Grid> : null}
        </div>
      </div>
        : null
      }
    </div>
  );
}