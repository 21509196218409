import React, { useEffect } from "react";
import MediaNetAd from '../../components/mediaNetAd';
import GamePg from '../game/game';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './index.scss';

export default function GamePage() {
  const { id } = useParams();
  const gamePaid = useSelector(({ firestore: { data } }) => {
    return data.games && data.games[id] && data.games[id].type !== 'paid'
  });

  return (
    <div className="game-pg-container">
      {gamePaid && <div className="min-heightz"><MediaNetAd /></div>}
      <div className="game-pg-container">
        <div className="game-container">
          <GamePg />
        </div>
      </div>
    </div>
  );
}