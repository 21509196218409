import React, {useRef} from 'react';
import {
  FileCopy
} from '@material-ui/icons';
import './index.scss';

export default function ShareModal(props) {
  const { gameId } = props;
  const linkToCopy = useRef(null);

  const copyToClipboard = () => {
    /* Get the text field */
    linkToCopy.current.select();
    linkToCopy.current.setSelectionRange(0, 99999); /*For mobile devices*/
  
    /* Copy the text inside the text field */
    document.execCommand('copy');
  
    /* Alert the copied text */
    alert('Share link copied!');
  };

  return (
    <div className="share-box">
      <h3>1. Copy and share this link with your friends to play</h3>
      <div className="copyUrl" onClick={copyToClipboard}>
        <span><FileCopy /></span>
        <input
          className="link-to-copy"
          type="text"
          value={`${window.location.origin}/join/${gameId}`}
          ref={linkToCopy}
        />
      </div>
    </div>
  )
}