import {auth} from '../../../store';


const getOpponents = (players, uid) => {
  let newPlayers;
  // console.log(',players', players);
    if (players) {
      // modify player data so you players are correctly positioned at table
      let getYourPosition = players.filter(player => {
        return player.opponentId === window.btoa(uid);
      });

      let yourPostion = getYourPosition[0].spotAtTable;

      let uiPlayers = [
        ...players
      ];

      let uiPlayersWoutYou = uiPlayers.splice(yourPostion, 1);
      let uiPlayersAfterYou = uiPlayers.splice(yourPostion); 
      let copyPlayersAfterYou = [...uiPlayersAfterYou];
      let uiPlayersBeforeYou = uiPlayers.splice(0, yourPostion); //leftver from array
      let copyPlayersBeforeYou = [...uiPlayersBeforeYou];

      if (uiPlayersWoutYou.length === 4) {
        uiPlayersWoutYou.reverse();
      }
       //NOTE: in order for UI to properly display players on left hand side, players after you must be in reverse order.

      // console.log('uiPlayers', uiPlayers);
      // console.log('players after you', uiPlayersAfterYou);
      // console.log('players before you', uiPlayersBeforeYou);

      let leftSidePlayers;
      let rightSidePlayers;

      if (copyPlayersBeforeYou.length) {
        //first let's add all players from the AFTER list.
        copyPlayersAfterYou = copyPlayersAfterYou.concat(copyPlayersBeforeYou);
      }

      if (copyPlayersAfterYou.length > 4) {
        leftSidePlayers = copyPlayersAfterYou.slice(0, 4); // grab first four, they neveed to reversed to display correctly
        rightSidePlayers = copyPlayersAfterYou.slice(4, copyPlayersAfterYou.length);
        leftSidePlayers.reverse();

        copyPlayersAfterYou = leftSidePlayers.concat(rightSidePlayers);
      } else if (copyPlayersBeforeYou.length <= 4) {
        leftSidePlayers = copyPlayersAfterYou.slice(0, 4); // grab first four, they neveed to reversed to display correctly
        copyPlayersAfterYou = leftSidePlayers.reverse();
      }

      let concated = copyPlayersAfterYou;

      let uiReady = concated.map((player, i) => {
        return {
          ...player,
          uiTableSpot: i+1
        };
      });

      newPlayers = uiReady;
    }

  return newPlayers;
};

export default getOpponents;