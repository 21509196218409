import {
  CREATE_FREE_GAME, 
  WARN_USER,
} from './constants';

export function free (state = {}, action) {
  switch (action.type) {
    case CREATE_FREE_GAME:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

