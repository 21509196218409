import 'whatwg-fetch';

const api = {
  start: '/startgame',
  update: '/updateGame',
  archive: '/archiveGame',
  ping: '/pingTime',
  savetransaction: '/savetransaction',
  getowngame: '/getowngame',
  freeGame: '/savefreegame',
  setplay: '/setplay',
  verifygame: '/game',
  addPlayer: '/addPlayer',
  addToQueue: '/addToQueue',
  redeemfreegame: '/redeemfreegame',
  extendHours: '/extendHours',
  gameOver: '/gameOver',
};

export default async function (type, method, data = {}) {
  try {
    const dataDefaults = {
      ...data,
      method: method || 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    
    const response = await fetch(api[type], dataDefaults);
    const json = await response.json();

    return json;
  } catch (e) {
    return {
      message: 'Fetch Error',
      e,
    };
  }
}


