import React from "react";
import MenuIcon from '@material-ui/icons/Menu';
import {IconButton,
  Typography,
  Toolbar,
  AppBar,
  ListItem,
  ListItemText,
  Drawer
} from '@material-ui/core';
import DonateButton from '../DonateButton';
import './index.scss';

import { useHistory } from "react-router-dom";

export default function HeaderBar() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(val);
  };

  const goToPage = (page) => {
    history.push(`/${page}`);
  };

  return (
    <div>
      <AppBar position="static" classes={{root: 'app-bar'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h5">
            Texas Hold Em With Friends
          </Typography>

          <div className="donate">
            <DonateButton variation="small"/>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <ListItem button onClick={() => {goToPage('')}}>
          <ListItemText primary={'Home'} />
        </ListItem>
        <ListItem button onClick={() => {goToPage('poker-winning-hands')}}>
          <ListItemText primary={'Poker Winning Hands'} />
        </ListItem>
        <ListItem button onClick={() => {goToPage('terms')}}>
          <ListItemText primary={'Terms Of Service'} />
        </ListItem>
        <ListItem button onClick={() => {goToPage('contact')}}>
          <ListItemText primary={'Contact Us'} />
        </ListItem>
        <ListItem button onClick={() => {goToPage('faq')}}>
          <ListItemText primary={'FAQ'} />
        </ListItem>
        <ListItem button>
          <ListItemText primary={'Support Us'} onClick={() => goToPage('supportus')} />
        </ListItem>
      </Drawer>
    </div>
  );
}
