import React, { useEffect } from "react";
import './index.scss';

export default function Loader() {
  useEffect(() => {
  });

  return (
    <div className="lds-dual-ring"></div>
  );
}