import React, { useEffect, useState } from "react";
import SingleCard from '../../components/SingleCard';
import DeckBack from '../../components/cards/deck-back';

export default function Flop(props) {
  let {
    board,
    inBetweenRound,
    gamePosition,
    manualGamePosition,
    inBetweenManualRound
  } = props;
  board = board.slice(0,3); // just get first 3 every time

  const [showManualCards, setShowManualCards] = useState(null);
  const showOurFlop = (showIt) => {
    setShowManualCards(showIt);
  };
  
  useEffect(() => {
    // console.log('?????????????     manualGamePosition', manualGamePosition);
    // let's check if game was manually set. If it was set because we delivered the ALL-IN game at once, 
    // then we need to see if it's during the FLOP, to delay showing the cards
    if (manualGamePosition === 'flop' || manualGamePosition === 'turn' || manualGamePosition === 'river') {
      showOurFlop(true);
  
      return () => {};
    } else {
      showOurFlop(false);
    }
  });

  const GetCards = board.map((cardId, key) => {
    if (manualGamePosition) {
      if (showManualCards) {
        if (cardId && cardId.length >=2) {
          return (
            <SingleCard cardId={cardId} key={key}/>
          );
        }
      } else {
        return <DeckBack key={key} />;
      }
    } else {
        // if not in between rounds, display the cards. We also want to display it if we're either on flop or river
      if (!inBetweenRound || (gamePosition === 'turn' || gamePosition === 'river')) {

        if (cardId && cardId.length >=2) {
          return (
            <SingleCard cardId={cardId} key={key}/>
          );
        }

        return <DeckBack key={key} />;
      }
      return <DeckBack key={key} />;
    }
  });

  return (
    <div>
      { board.length > 1 ? GetCards : null}
    </div>
  );
}