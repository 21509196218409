import React, { useEffect } from "react";
import './chipcount.scss';
import {
  DataUsage
} from '@material-ui/icons';

export default function ChipCount(props) {
  const { chips } = props;

  useEffect(() => {
  });

  return (
    <div className="chip-count xs-sd-padding">
      <DataUsage className="chip-icon"></DataUsage> {chips} chips
    </div>
  );
}