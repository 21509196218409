import React, { useEffect, useState } from "react";
import SingleCard from '../SingleCard';
import DeckBack from '../cards/deck-back';

export default function Turn(props) {
  let {
    board,
    inBetweenRound,
    gamePosition,
    manualGamePosition,
    inBetweenManualRound
  } = props;
  board = board.slice(3,4);

  const [showManualCards, setShowManualCards] = useState(null);
  const showOurTurn = (showIt) => {
    setShowManualCards(showIt);
  };
  
  useEffect(() => {
    // let's check if game was manually set. If it was set because we delivered the ALL-IN game at once, 
    // then we need to see if it's during the FLOP, to delay showing the cards
    if (manualGamePosition === 'turn' || manualGamePosition === 'river') {
      // setTimeout(() => {
        showOurTurn(true);
      // }, 4000);
  
      return () => {};
    } else {
      showOurTurn(false);
    }
  });

  const GetCards = board.map((cardId, key) => {
    if (manualGamePosition) {
      // NOTE: if are on the turn, wait 8 seconds before showing the card
      if (manualGamePosition === 'turn') {
        if (showManualCards) {
          if (cardId && cardId.length >=2) {
            return (
              <SingleCard cardId={cardId} key={key}/>
            );
          }
        } else {
          return <DeckBack key={key} />;
        }
      } else if (manualGamePosition === 'river' || manualGamePosition === 'round-over') { // NOTE IF WE ARE ON RIVER, then it's okay to show cards at all times
        if (cardId && cardId.length >=2) {
          return (
            <SingleCard cardId={cardId} key={key}/>
          );
        }
      } else {
        return <DeckBack key={key} />;
      }

    } else {

      if (!inBetweenRound || gamePosition === 'river') {
        if (cardId && cardId.length >=2) {
          return (
            <SingleCard cardId={cardId} key={key}/>
          );
        }
        return <DeckBack key={key} />;
      } 
      return <DeckBack key={key} />;
    }
    return <DeckBack key={key} />;
  });

  return (
    <span>
      { board.length >= 1 ? GetCards : null}
    </span>
  );
}