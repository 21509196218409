import React, {useEffect} from "react";
import MediaNetAd from '../../components/mediaNetAd';
import LobbyPg from '../lobby/index';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './index.scss';

export default function LobbyPages() {
  const { id } = useParams();
  const gamePaid = useSelector(({ firestore: { data } }) => {
    return data.games && data.games[id] && data.games[id].type !== 'paid';
  });

  return (
    <div className="lobby page">
      <div className="content">
        {gamePaid && <MediaNetAd />}
        <LobbyPg />
      </div>
    </div>
  );
}