import React from 'react';
import './index.scss';
import SingleCard from '../../components/SingleCard';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  WarningRounded
} from '@material-ui/icons';


export default function YourHand(props) {
  const {
    yourCards,
    retryOwnCards
  } = props;

  const retryFetch = () => {
    retryOwnCards();
  };

  const GetCards = () => {
    if (yourCards.indexOf('xx') >= 0) {
      return <div onClick={() => retryFetch()} className="card-try-again">
        <p><WarningRounded /><br />Cards failed to load.<br />Click to try again.</p>
      </div>;
    }

    return yourCards.map((cardId, key) => {
      return (
        <SingleCard cardId={cardId} key={key}/>
      );
    });
  };

  const GetBlinded = () => {
    return (
      <div>
        <Skeleton variant="rect" animation="wave" />
        <Skeleton variant="rect" animation="wave" />
      </div>
    );
  };

  return (
    <div className="card-couple">
      { yourCards.length >= 2 ? <GetCards /> : <GetBlinded />}
    </div>
  );
}