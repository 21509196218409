import React, { useEffect } from "react";
import './index.scss';
import {
  DataUsage
} from '@material-ui/icons';
import Opponents from '../Opponents';
import YourGame from '../YourGame';
import Flop from '../Flop';
import Turn from '../Turn';
import River from '../River';
import DeckBack from '../cards/deck-back';

export default function Table(props) {
  const {
    board,
    yourCards,
    opponents,
    bigBlindId,
    smallBlindId,
    dealerId,
    yourGameData,
    currentPlayerTurn,
    currentPot,
    yourBet,
    inBetweenRound,
    gamePosition,
    manualGamePosition,
    inBetweenManualRound,
    retryOwnCards
  } = props;

  useEffect(() => {
  });

  const showBurnedFlop = () => {
    return manualGamePosition === 'flop' ||
    manualGamePosition === 'turn' ||
    manualGamePosition === 'river' ||
    gamePosition === 'flop' ||
    gamePosition === 'turn' ||
    gamePosition === 'river';
  };
  
  const showBurnedTurn = () => {
    return manualGamePosition === 'turn' ||
    manualGamePosition === 'river' ||
    gamePosition === 'turn' ||
    gamePosition === 'river';
  };

  const showBurnedRiver = () => {
    return manualGamePosition === 'river' ||
    gamePosition === 'river'
  };

  return (
    <div className="poker-table">
      <YourGame
        manualGamePosition={manualGamePosition}
        yourCards={yourCards}
        yourData={yourGameData}
        dealerId={dealerId}
        bigBlindId={bigBlindId}
        smallBlindId={smallBlindId}
        currentPlayerTurn={currentPlayerTurn}
        yourBet={yourBet}
        retryOwnCards={retryOwnCards}
      />

      <Opponents
        manualGamePosition={manualGamePosition}
        currentPlayerTurn={currentPlayerTurn}
        opponentList={opponents}
        dealerId={dealerId}
        bigBlindId={bigBlindId}
        smallBlindId={smallBlindId}
      />

      <div className="community-cards">
        <header>
          <h1>Flop</h1>
        </header>
        <div className="flop">
          <Flop
            inBetweenManualRound={inBetweenManualRound}
            manualGamePosition={manualGamePosition}
            board={board}
            inBetweenRound={inBetweenRound}
            gamePosition={gamePosition}
          />
        </div>
        <div className="turn-and-river">
          <div className="turn-card">
            <h1>Turn</h1>
            <Turn
              inBetweenManualRound={inBetweenManualRound}
              manualGamePosition={manualGamePosition}
              board={board}
              inBetweenRound={inBetweenRound}
              gamePosition={gamePosition}
            />
          </div>
          <div className="river-card">
            <h1>River</h1>
            <River
              inBetweenManualRound={inBetweenManualRound}
              manualGamePosition={manualGamePosition}
              board={board}
              inBetweenRound={inBetweenRound}
              gamePosition={gamePosition}
            />
          </div>
          <div class="burned-cards">
            { showBurnedFlop() ? <span className="flop-burned"><DeckBack /></span> : null}
            { showBurnedTurn() ? <span className="turn-burned"><DeckBack /></span>: null}
            { showBurnedRiver() ?<span className="river-burned"><DeckBack /></span> : null}
          </div>
        </div>
        <div className="pot-total">
          <span>Total pot: </span><DataUsage className="chip-icon"></DataUsage> {currentPot} chips
        </div>
      </div>
    </div>
  );
}