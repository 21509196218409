
import './index.scss';
import PokerRankingsList from '../pokerrankingslist';
import React, { useEffect, useState, useRef } from "react";
import { Modal } from '@material-ui/core';

export default function PokerRankings({
  open,
  close
}) {

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={close}
      className="poker-rankings-modal"
    >
      <div className="poker-rankings">

        <a className="btn-close" onClick={close}>CLOSE</a>

        <PokerRankingsList />
      </div>
    </Modal>

  )
}